import React from 'react';
import './Delegations.css';

function Delegations() {
    return (
        <div className="News">
            <h1>Delegations</h1>
        </div>
    );
}

export default Delegations;
