import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import './Holiday.css';
import {
    Button,
    Card,
    DatePicker,
    Divider,
    Empty,
    Form,
    Input,
    List,
    message,
    Space,
    Statistic,
    Table,
    Tag,
    Tooltip
} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import {
    CheckOutlined,
    CloseOutlined,
    NumberOutlined,
    CarOutlined,
    QuestionOutlined,
    DownloadOutlined
} from '@ant-design/icons';
import {useAuth} from "../../extensions/Auth";
import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import 'dayjs/plugin/updateLocale';
import pl from 'antd/es/date-picker/locale/pl_PL';
import {
    getEaster,
    HolidayDayName,
    HolidayDescriptionMap,
    HolidayStatus,
    isDayOff
} from "../../extensions/DateFormatter";
import {HolidayService} from "../../services/HolidayService";
import {DEFAULT_CITY} from "../../services/LoginService";

const {RangePicker} = DatePicker;

interface HolidayDate {
    date: string;
    type: HolidayDayName;
}


export interface HolidayEntry {
    id: string;
    name: string;
    startDate: string;
    endDate: string;
    duration: number;
    substitution: string;
    status: HolidayStatus;
    organisation: string;
    position: string;
    forYear: number;
    city: string;
}

const colorMap: any = {
    [HolidayStatus.Accepted]: 'green',
    [HolidayStatus.Pending]: 'orange',
    [HolidayStatus.Rejected]: 'red',
    [HolidayStatus.Completed]: 'blue',
}

function ChangeHolidayStatus(id: string, status: HolidayStatus, setData: Dispatch<SetStateAction<HolidayEntry[]>>) {
    HolidayService.updateHolidayStatus(id, status).then(async (r) => {
        if(r) {
            setData(await HolidayService.getAllHoliday());
        }
    });
}

function Holiday() {
    const [form] = Form.useForm();
    const auth = useAuth();
    const [holidayDates, setHolidayDates] = useState<HolidayDate[]>([]);
    const [messageApi, contextHolder] = message.useMessage();

    const [holidayList, setHolidayList] = useState<HolidayEntry[]>([]);

    const columns: ColumnsType<HolidayEntry> = [
        {
            title: 'Data rozpoczęcia',
            dataIndex: 'startDate',
            key: 'startDate',
            render: (text) => dayjs(text).format('DD.MM.YYYY'),
        },
        {
            title: 'Data zakończenia',
            dataIndex: 'endDate',
            key: 'endDate',
            render: (text) => dayjs(text).format('DD.MM.YYYY'),
        },
        {
            title: 'Liczba dni',
            dataIndex: 'duration',
            key: 'duration',
            responsive: ['md'],
        },
        {
            title: 'Zastępstwo',
            dataIndex: 'substitution',
            key: 'substitution',
            responsive: ['md'],
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            responsive: ['md'],
            render: (text) => <Tag color={colorMap[text]}>{HolidayDescriptionMap(text)}</Tag>,
        },
        {
            title: 'Akcje',
            key: 'action',
            responsive: ['lg'],
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip title="Urlop odbyty">
                        <CarOutlined
                            onClick={() => ChangeHolidayStatus(record.id, HolidayStatus.Completed, setHolidayList)}/>
                    </Tooltip>
                    <Tooltip title="Urlop zaakceptowany">
                        <CheckOutlined
                            onClick={() => ChangeHolidayStatus(record.id, HolidayStatus.Accepted, setHolidayList)}/>
                    </Tooltip>
                    <Tooltip title="Urlop odrzucony">
                        <CloseOutlined
                            onClick={() => ChangeHolidayStatus(record.id, HolidayStatus.Rejected, setHolidayList)}/>
                    </Tooltip>
                    <Tooltip title="Oczekujący na akceptację">
                        <QuestionOutlined
                            onClick={() => ChangeHolidayStatus(record.id, HolidayStatus.Pending, setHolidayList)}/>
                    </Tooltip>
                    <Tooltip title="Pobierz formularz">
                        <DownloadOutlined
                            onClick={() => HolidayService.downloadForm(record.id)}/>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const calculateByStatus = (status: HolidayStatus) => {
        return holidayList.filter(x => x.status === status).reduce((a, b) => a + b.duration, 0);
    }

    const prepareForm = () => {
        form.setFieldsValue({
            name: auth.user?.firstName + ' ' + auth.user?.lastName,
            organisation: auth.user?.organisation,
            position: auth.user?.position,
            duration: 0,
            forYear: dayjs().year(),
            city: DEFAULT_CITY
        });
    }

    const onFinish = () => {
        const values = form.getFieldsValue();

        if (!values.dateRange || !values.dateRange[0] || !values.dateRange[1]) {
            messageApi.error('Data rozpoczęcia i zakończenia urlopu jest wymagana!');
            return;
        }

        const holidayRequest = {
            id: (holidayList.length + 1).toString(),
            name: values.name,
            startDate: values.dateRange[0].toISOString(),
            endDate: values.dateRange[1].toISOString(),
            duration: calculateDifference(values.dateRange[0].toDate(), values.dateRange[1].toDate()),
            substitution: values.substitution,
            forYear: values.forYear,
            city: values.city,
            status: HolidayStatus.Pending,
            position: values.position,
            organisation: values.organisation
        }

        HolidayService.addHoliday(holidayRequest).then((x) => {
            setHolidayList([...holidayList, x]);
            messageApi.success('Wniosek o urlop został wygenerowany!').then(() => {
                form.resetFields()
                prepareForm();
            });
        });
    };

    const onFinishFailed = () => {
        messageApi.error('Formularz zawiera błędy!');
    };

    const calculateDifference = (d1: Date, d2: Date) => {
        let days = 0;
        let easter = getEaster(d1.getFullYear());
        const holidays = []

        while (d1 <= d2) {
            if (d1.getFullYear() !== easter.getFullYear())
                easter = getEaster(d1.getFullYear());

            const dayType = isDayOff(d1, easter);

            if (dayType === HolidayDayName.None)
                days++;
            else if (dayType !== HolidayDayName.Sunday && dayType !== HolidayDayName.Saturday)
                holidays.push({
                    date: dayjs(d1).format('DD.MM.YYYY'),
                    type: dayType
                });

            d1.setDate(d1.getDate() + 1);
        }

        setHolidayDates(holidays);

        return days;
    }

    const calculateDuration = (dates: any, dateStrings: [string, string]) => {
        form.setFieldsValue({duration: calculateDifference(dates[0].toDate(), dates[1].toDate())});
    }

    useEffect(() => {
        HolidayService.getAllHoliday().then(x => setHolidayList(x));
        prepareForm();
    }, []);

    return (
        <div className="holiday">
            <Card className={'holiday__header-card'} title={'Wypełnij wniosek o urlop'}>
                <p>Wypełniająć wniosek o urlop za pomocą poniższego formularza, wygenerujemy dla Ciebie dokument,
                    który będziesz mógł wydrukować i przekazać do swojego przełożonego w celu akceptacji.</p>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <div className="holiday__form-row">
                        <Form.Item
                            name="name"
                            label="Imię i nazwisko"
                            rules={[{required: true, message: 'Imię i nazwisko jest wymagane'}]}
                        >
                            <Input placeholder="Podaj imię i nazwisko..." size={'large'}/>
                        </Form.Item>
                        <Form.Item
                            name="organisation"
                            label="Komórka organizacyjna"
                            rules={[{required: true, message: 'Komórka organizacyjna jest wymagana'}]}
                        >
                            <Input placeholder="Podaj nazwę komórki organizacyjnej..." size={'large'}/>
                        </Form.Item>
                        <Form.Item
                            name="position"
                            label="Stanowisko"
                            rules={[{required: true, message: 'Stanowisko jest wymagane'}]}
                        >
                            <Input placeholder="Podaj stanowisko..." size={'large'}/>
                        </Form.Item>
                        <Form.Item
                            name="substitution"
                            label="Osoba zastępująca"
                        >
                            <Input placeholder="Podaj imię i nazwisko osoby zastępującej..." size={'large'}/>
                        </Form.Item>
                    </div>
                    <div className="holiday__form-date-row">
                        <Form.Item label="Data rozpoczęcia urlopu" name={'dateRange'} style={{flex: 1}}>
                            <RangePicker size={'large'} format={'DD.MM.YYYY'}
                                         locale={pl}
                                         onChange={calculateDuration}
                                         placement={'bottomLeft'}
                                         placeholder={['Data rozpoczęcia', 'Data zakończenia']}
                                         style={{width: '100%'}}
                                         disabledDate={(current) => current && current < dayjs().subtract(1, 'day')}/>
                        </Form.Item>
                        <div className={'holiday__form-date-row-sub'}>
                            <Form.Item
                                name="duration"
                                label="Długość urlopu"
                            >
                                <Input size={'large'} disabled/>
                            </Form.Item>
                            <Form.Item
                                name="forYear"
                                label="Urlop za rok"
                                rules={[{required: true, message: 'Urlop za rok jest polem wymaganym'}]}
                            >
                                <Input size={'large'} placeholder={'2023'}/>
                            </Form.Item>
                            <Form.Item
                                name="city"
                                label="Miejscowość (skąd składany jest wniosek)"
                                rules={[{required: true, message: 'Miejscowość jest wymagana'}]}
                            >
                                <Input size={'large'} placeholder={DEFAULT_CITY}/>
                            </Form.Item>
                        </div>
                    </div>
                    <div className={'holiday__form-bank-holidays'}>
                        {holidayDates.length > 0 && <div>
                            <h4>Poniższe daty są dniami ustawowo wolnymi od pracy</h4>
                            <List
                                size="small"
                                bordered
                                dataSource={holidayDates}
                                renderItem={(item, index) => <List.Item
                                    key={index}>{item.date} ({item.type})</List.Item>}
                            />
                        </div>}
                    </div>
                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                Przygotuj wniosek
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
                <Divider/>
                <div className={'holiday__total-days'}>
                    <div className={'holiday__total-days-cards'}>
                        <Card bordered={false}>
                            <Statistic title="Dni wolne w roku"
                                       value={auth.user?.holidayAllowance! - calculateByStatus(HolidayStatus.Completed)}
                                       prefix={<NumberOutlined/>}/>
                        </Card>
                        <Card bordered={false}>
                            <Statistic title="Urlop oczekujący na akceptację"
                                       value={calculateByStatus(HolidayStatus.Pending)}
                                       prefix={<QuestionOutlined/>}/>
                        </Card>
                        <Card bordered={false}>
                            <Statistic title="Urlop odrzucony" value={calculateByStatus(HolidayStatus.Rejected)}
                                       prefix={<CloseOutlined/>}/>
                        </Card>
                        <Card bordered={false}>
                            <Statistic title="Urlop zaakceptowany (niedobyty)"
                                       value={calculateByStatus(HolidayStatus.Accepted)}
                                       prefix={<CheckOutlined/>}/>
                        </Card>
                        <Card bordered={false}>
                            <Statistic title="Urlop wykorzystany" value={calculateByStatus(HolidayStatus.Completed)}
                                       prefix={<CarOutlined/>}/>
                        </Card>
                    </div>

                    <Table columns={columns} dataSource={holidayList} rowKey={'id'}
                           locale={{emptyText: <Empty description={'Brak danych'}/>}}/>
                </div>
            </Card>
            {contextHolder}
        </div>
    );
}

export default Holiday;
