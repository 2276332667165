import { Typography, List } from 'antd';

const { Title } = Typography;

const news = [
    {
        key: '1',
        title: 'Nowe obostrzenia w związku z trzecią falą pandemii',
        content:
            'W związku z trzecią falą pandemii w naszym kraju, rząd wprowadza nowe obostrzenia, które mają na celu ograniczenie rozprzestrzeniania się wirusa. Obowiązkowe jest noszenie maseczek w przestrzeni publicznej, a także ograniczenie liczby osób w sklepach i kościołach.',
        date: '15.04.2023',
    },
    {
        key: '2',
        title: 'Szczepienia przeciw COVID-19',
        content:
            'Rząd zachęca do szczepień przeciwko COVID-19. Szczepienia są bezpłatne i dobrowolne. Osoby, które zaszczepią się przeciwko COVID-19, mają mniejsze szanse na ciężki przebieg choroby i mogą pomóc w ograniczeniu rozprzestrzeniania się wirusa.',
        date: '10.04.2023',
    },
];

const recommendations = [
    {
        key: '1',
        content:
            'Myj ręce często i dokładnie mydłem oraz wodą bieżącą przez co najmniej 20 sekund.',
    },
    {
        key: '2',
        content:
            'Zachowaj bezpieczny dystans (co najmniej 1,5 metra) od innych osób.',
    },
    {
        key: '3',
        content:
            'Unikaj dotykania oczu, nosa i ust, szczególnie po kontakcie z innymi osobami.',
    },
    {
        key: '4',
        content: 'Zawsze zakrywaj usta i nos, gdy kaszlesz lub kichasz.',
    },
    {
        key: '5',
        content:
            'Unikaj dużych zgromadzeń i przestrzegaj zasad bezpieczeństwa podczas spotkań z innymi ludźmi.',
    },
];

const Covid = () => {
    return (
        <>
            <Title level={4}>Aktualności</Title>
            <List
                dataSource={news}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta
                            title={item.title}
                            description={item.date}
                        />
                        {item.content}
                    </List.Item>
                )}
            />
            <Title level={4}>Zalecenia</Title>
            <List
                dataSource={recommendations}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta
                            title={`Zalecenie ${item.key}`}
                        />
                        {item.content}
                    </List.Item>
                )}
            />
        </>
    );
};

export default Covid;