import React, {useEffect, useState} from 'react';
import './TrainingRedaction.css';
import {Button, Pagination, Spin} from "antd";
import NewsCard, {NewsCardProps} from "../../components/newscard/NewsCard";
import {NewsService} from "../../services/NewsService";
import {useAuth} from "../../extensions/Auth";
import {AdminScopes} from "../../types/Scopes";
import {TrainingService} from "../../services/TrainingService";

function TrainingRedaction() {
    const auth = useAuth();
    const [page, setPage] = useState(1);
    const [data, setData] = useState([] as NewsCardProps[]);
    const [trainingData, setTrainingData] = useState([] as NewsCardProps[]);
    const [loading, setLoading] = useState(true);

    const filter = (p = 1, input = data) => setTrainingData(input.filter((_, index) => index >= (p - 1) * 10 && index < p * 10));

    useEffect(() => {
        auth.verifyAuth(AdminScopes.TRAINING);

        setLoading(true);
        TrainingService.getPendingTrainingCourses().then((r) => {
            setData(r);
            filter(page, r);
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
            auth.handleLogout();
        });
    }, []);

    const updatePagination = (page: number) => {
        setPage(page);
        filter(page);
    }

    return (
        <div className="training-redaction">
            <div className="training-redaction__content">
                {!loading && trainingData.map((news, index) => <NewsCard key={index} {...news} className={'training-redaction__card'} withApproval={true} isTraining={true} />)}
                {loading && <Spin tip="Ładowanie..." size="large" className={'loader'}/>}
            </div>
            <Pagination defaultCurrent={1} total={data.length} onChange={updatePagination} showSizeChanger={false}/>
        </div>
    );
}

export default TrainingRedaction;
