import React from 'react';
import {Typography, Descriptions, Button, Card} from 'antd';

const {Title, Paragraph} = Typography;

const OrganizationalMatters = () => {
    return (
        <div>
            <Title level={4}>Sprawy organizacyjne</Title>
            <Card
                className="site-page-header"
                title="Wszystkie potrzebne informacje w jednym miejscu"
                extra={[
                    <Button key="1" type="primary">
                        Złóż wniosek
                    </Button>,
                    <Button key="2">Kontakt</Button>,
                ]}
            >
                <Descriptions size="small" column={2}>
                    <Descriptions.Item label="Godziny otwarcia">
                        Poniedziałek - piątek: 8:00 - 16:00
                    </Descriptions.Item>
                    <Descriptions.Item label="Adres">
                        ul. Przykładowa 10, 00-000 Warszawa
                    </Descriptions.Item>
                    <Descriptions.Item label="Telefon">+48 123 456 789</Descriptions.Item>
                    <Descriptions.Item label="E-mail">
                        <a href="mailto:biuro@example.com">biuro@example.com</a>
                    </Descriptions.Item>
                </Descriptions>
            </Card>
            <div style={{ padding: '0 24px' }}>
                <h2>Ważne informacje</h2>
                <ul>
                    <li>W przypadku zwolnienia z pracy należy zwrócić firmie wszystkie sprzęty i narzędzia pracy.</li>
                    <li>Pracownicy mają prawo do jednej przerwy na lunch trwającej 30 minut.</li>
                    <li>W urzędzie obowiązuje zakaz palenia papierosów na terenie budynku.</li>
                </ul>
            </div>
        </div>
    );
};

export default OrganizationalMatters;