import {List, Typography} from 'antd';

const {Title, Paragraph} = Typography;

const regulations = [{
    title: "Postanowienia ogólne",
    content: ["Pracownicy zobowiązani są do przestrzegania niniejszego regulaminu.", "Wszelkie nieprawidłowości należy niezwłocznie zgłaszać bezpośrednio przełożonemu.", "Za naruszenie postanowień regulaminu pracownik może zostać ukarany w zależności od stopnia jego wykroczenia.",],
},
    {
        title: "Warunki zatrudnienia",
        content: [
            "Pracownik zatrudniony jest na podstawie umowy o pracę na okres próbny",
            "Pracodawca przysługuje prawo do jednostronnego rozwiązania umowy o pracę w przypadku naruszenia przez pracownika postanowień regulaminu.",
            "Pracownik zobowiązany jest do zachowania poufności informacji uzyskanych w związku z wykonywaną pracą.",
            "Pracownik jest zobowiązany do wykonywania swoich obowiązków sumiennie i rzetelnie, a także do zgłaszania przełożonemu wszelkich nieprawidłowości w pracy.",
        ],
    },
    {
        title: "Obowiązki pracownika",
        content: [
            "Pracownik zobowiązany jest do przestrzegania regulaminu firmy oraz przepisów BHP.",
            "Pracownik zobowiązany jest do dbałości o powierzone mu mienie firmy.",
            "Pracownik jest zobowiązany do przestrzegania norm etykiety i dobrych obyczajów.",
            "Pracownik zobowiązany jest do przestrzegania regulaminu dotyczącego korzystania z narzędzi informatycznych.",
            "Pracownik zobowiązany jest do przestrzegania przepisów dotyczących ochrony danych osobowych.",
        ],
    },
    {
        title: "Wynagrodzenie i premie",
        content: [
            "Wynagrodzenie za pracę wypłacane jest co miesiąc w terminie do 10 dnia miesiąca następującego po miesiącu, za który przysługuje wynagrodzenie.",
            "Pracownik może otrzymać premię zależną od uzyskanych wyników lub za szczególne osiągnięcia w pracy.",
            "Premia nie jest gwarantowana i jej przyznanie zależy od decyzji pracodawcy.",
        ],
    },
];

const Regulations = () => {
    return (
        <>
            <Title level={4}>Regulamin pracodawcy</Title>
            <ol>
                {regulations.map((regulation, index) => (
                    <li key={index}>
                        <Title level={5}>{regulation.title}</Title>
                        <ol>
                            {regulation.content.map((item, index) => (
                                <li>{item}</li>
                            ))}
                        </ol>
                    </li>
                ))}
            </ol>
        </>
    );
};

export default Regulations;