import React, {useEffect, useState} from 'react';
import './UserAdd.css';
import {useAuth} from "../../extensions/Auth";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {InfoCircleTwoTone} from "@ant-design/icons";
import {Button, Card, Input, notification, Select, Skeleton} from "antd";
import {AdminScopes} from "../../types/Scopes";
import {ScopeMapper} from "../users/Users";
import {UserService} from "../../services/UserService";
import {User} from "../../types/AuthState";
import {useParams} from "react-router-dom";

const {Meta} = Card;

export interface UserAddProps {
    isEdit?: boolean;
}

function UserAdd({isEdit}: UserAddProps) {
    const auth = useAuth();
    let { userEmail } = useParams();

    const [api, contextHolder] = notification.useNotification();

    const [form, setForm] = useState({scopes: []} as any);

    const updateForm = (e: any, field: string) => {
        setForm({...form, [field]: e.target.value});
    }

    const updateFormScopes = (e: any) => {
        setForm({...form, scopes: e});
    }

    const publish = () => {
        const keys = ['firstName', 'lastName', 'holidayAllowance', 'organisation', 'position', 'email'];

        if (!form.firstName || !form.lastName || !form.holidayAllowance || !form.organisation || !form.position || !form.email) {
            const errors = [];
            if (!form.firstName) errors.push('Imię');
            if (!form.lastName) errors.push('Nazwisko');
            if (!form.holidayAllowance) errors.push('Dni urlopowe');
            if (!form.organisation) errors.push('Dział');
            if (!form.position) errors.push('Stanowisko');
            if (!form.email) errors.push('Email');

            api.error({
                message: `Uzupełnij wymagane pola`,
                description: `${errors.join(', ')} ${errors.length === 1 ? 'jest' : 'są'} wymagane!`,
                placement: 'top'
            });
            return;
        }

        for(const key of keys) {
            if(form[key].length > 70) {
                api.error({
                    message: `Zbyt długa wartość`,
                    description: `${key} może mieć maksymalnie 70 znaków!`,
                    placement: 'top'
                });
                return;
            }
        }

        const user: User = {
            isAdmin: form.scopes.length > 0,
            isAuthenticated: false,
            name: `${form.firstName} ${form.lastName}`,
            firstName: form.firstName,
            lastName: form.lastName,
            holidayAllowance: form.holidayAllowance,
            organisation: form.organisation,
            position: form.position,
            email: form.email,
            scopes: form.scopes,
            avatar: form.avatar ?? 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png'
        }

        try {
            if(isEdit)
                UserService.editUser(user, userEmail!).then((x) => addEditCallback(x, keys));
            else
                UserService.addUser(user).then((x) => addEditCallback(x, keys));
        } catch (e) {
            api.error({
                message: `Błąd`,
                description: 'Wystąpił błąd podczas dodawania użytkownika!',
                placement: 'top'
            });
        }
    }

    const addEditCallback = (x: any, keys: string[]) => {
        if ("error" in x) {
            api.error({
                message: `Błąd`,
                description: x.error,
                placement: 'top'
            });
            return;
        }

        api.info({
            message: `${isEdit ? 'Zaktualizowano' : 'Dodano'} użytkownika`,
            description: `Użytkownik został ${isEdit ? 'zaktualizowany' : 'dodany'}!`,
            placement: 'top'
        });

        if(!isEdit) {
            const newForm = {...form};

            for(const key of keys) {
                newForm[key] = '';
            }

            setForm(newForm);
        }
    }

    useEffect(() => {
        auth.verifyAuth(AdminScopes.USERS);

        if(isEdit && userEmail) {
            UserService.getUser(userEmail).then((x) => {
                if ("error" in x) {
                    api.error({
                        message: `Błąd`,
                        description: x.error,
                        placement: 'top'
                    });
                    auth.navigate('/users');
                    return;
                }

                setForm({
                    firstName: x.firstName,
                    lastName: x.lastName,
                    holidayAllowance: x.holidayAllowance,
                    organisation: x.organisation,
                    position: x.position,
                    email: x.email,
                    scopes: x.scopes,
                    avatar: x.avatar
                });
            });
        }
    }, []);

    return (
        <div className="user-add">
            {contextHolder}
            {!isEdit && <Card className={'user-add__info'}>
                <Skeleton avatar active loading={false}>
                    <Meta
                        avatar={<InfoCircleTwoTone style={{fontSize: '2rem'}}/>}
                        title="Dodawanie użytkownika"
                        description={<div>
                            <p>W tej sekcji możesz utworzyć nowego użytkownika.</p>
                            <p>Domyślnie, hasło użytkownika będzie wygenerowane losowo i użytkownik otrzyma je na podany
                                adres email. Hasło będzie można zmienić po pierwszym zalogowaniu.</p>
                            <small>Pola oznaczone gwiazdką są wymagane</small>
                        </div>}
                    />
                </Skeleton>
            </Card>}
            {isEdit && <Card className={'user-add__info'}>
                <Skeleton avatar active loading={false}>
                    <Meta
                        avatar={<InfoCircleTwoTone style={{fontSize: '2rem'}}/>}
                        title="Edytowanie użytkownika"
                        description={<div>
                            <p>W tej sekcji możesz zmienić informacje użytkownika.</p>
                            <small>Pola oznaczone gwiazdką są wymagane</small>
                        </div>}
                    />
                </Skeleton>
            </Card>}
            <div className={'user-add__form-row'}>
                <div className={'user-add__form-field-wrapper'}>
                    <label>Imię <b>*</b></label>
                    <Input size="large" placeholder="Jan" value={form['firstName']}
                           onChange={(e: any) => updateForm(e, 'firstName')} className={'user-add__form-field'}
                           required/>
                </div>
                <div className={'user-add__form-field-wrapper'}>
                    <label>Nazwisko <b>*</b></label>
                    <Input size="large" placeholder="Nowak" value={form['lastName']}
                           onChange={(e: any) => updateForm(e, 'lastName')} className={'user-add__form-field'}/>
                </div>
            </div>
            <div className={'user-add__form-row'}>
                <div className={'user-add__form-field-wrapper'}>
                    <label>Dział <b>*</b></label>
                    <Input size="large" placeholder="Administracja" value={form['organisation']}
                           onChange={(e: any) => updateForm(e, 'organisation')} className={'user-add__form-field'}/>
                </div>
                <div className={'user-add__form-field-wrapper'}>
                    <label>Stanowisko <b>*</b></label>
                    <Input size="large" placeholder="Kierownik działu..." value={form['position']}
                           onChange={(e: any) => updateForm(e, 'position')} className={'user-add__form-field'}/>
                </div>
            </div>
            <div className={'user-add__form-row'}>
                <div className={'user-add__form-field-wrapper'}>
                    <label>Adres email <b>*</b></label>
                    <Input size="large" placeholder="jan@nowak.pl" value={form['email']}
                           onChange={(e: any) => updateForm(e, 'email')} className={'user-add__form-field'}/>
                </div>
                <div className={'user-add__form-field-wrapper'}>
                    <label>Liczba dni urlopu (w roku) <b>*</b></label>
                    <Input size="large" type={'number'} placeholder="25" value={form['holidayAllowance']}
                           onChange={(e: any) => updateForm(e, 'holidayAllowance')} className={'user-add__form-field'}/>
                </div>
            </div>
            <div className={'user-add__form-row'}>
                <div className={'user-add__form-field-wrapper'}>
                    <label>Link do zdjęcia użytkownika</label>
                    <Input size="large" placeholder="https://..." value={form['avatar']}
                           onChange={(e: any) => updateForm(e, 'avatar')} className={'user-add__form-field'}/>
                </div>
            </div>
            <div className={'user-add__form-row'}>
                <div className={'user-add__form-field-wrapper'}>
                    <label>Uprawnienia administracyjne</label>
                    <Select
                        className={'user-add__form-field'}
                        mode="multiple"
                        size={'large'}
                        placeholder="Wybierz uprawnienia użytkownika"
                        value={form.scopes}
                        onChange={(e: any) => updateFormScopes(e)}
                        style={{width: '100%'}}
                        options={Object.keys(AdminScopes).map((key: number | string) => {
                            return ({
                                value: AdminScopes[key as keyof typeof AdminScopes],
                                label: ScopeMapper(AdminScopes[key as keyof typeof AdminScopes])
                            });
                        })}
                    />
                </div>
            </div>
            <Button type={'primary'} onClick={() => publish()}>{isEdit ? 'Zaktualizuj' : 'Dodaj'}</Button>
        </div>
    );
}

export default UserAdd;
