import React, {useEffect, useState} from 'react';
import './Profile.css';
import {Button, Card, Input, notification, Skeleton} from "antd";
import Meta from "antd/es/card/Meta";
import {InfoCircleTwoTone} from "@ant-design/icons";
import {User} from "../../types/AuthState";
import {LoginService} from "../../services/LoginService";
import Login from "../login/Login";

function Profile() {
    const [api, contextHolder] = notification.useNotification();

    const [form, setForm] = useState({scopes: []} as any);
    const [passwords, setPasswords] = useState({} as any);

    const updateForm = (e: any, field: string) => {
        setForm({...form, [field]: e.target.value});
    }

    const publish = () => {
        const keys = ['firstName', 'lastName', 'holidayAllowance', 'organisation', 'position', 'email'];

        if (!form.firstName || !form.lastName || !form.holidayAllowance || !form.organisation || !form.position || !form.email) {
            const errors = [];
            if (!form.firstName) errors.push('Imię');
            if (!form.lastName) errors.push('Nazwisko');
            if (!form.holidayAllowance) errors.push('Dni urlopowe');
            if (!form.organisation) errors.push('Dział');
            if (!form.position) errors.push('Stanowisko');
            if (!form.email) errors.push('Email');

            api.error({
                message: `Uzupełnij wymagane pola`,
                description: `${errors.join(', ')} ${errors.length === 1 ? 'jest' : 'są'} wymagane!`,
                placement: 'top'
            });
            return;
        }

        for (const key of keys) {
            if (form[key].length > 70) {
                api.error({
                    message: `Zbyt długa wartość`,
                    description: `${key} może mieć maksymalnie 70 znaków!`,
                    placement: 'top'
                });
                return;
            }
        }

        const user: User = {
            isAdmin: form.scopes.length > 0,
            isAuthenticated: false,
            name: `${form.firstName} ${form.lastName}`,
            firstName: form.firstName,
            lastName: form.lastName,
            holidayAllowance: form.holidayAllowance,
            organisation: form.organisation,
            position: form.position,
            email: form.email,
            scopes: form.scopes,
            avatar: form.avatar ?? 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png'
        }

        try {
            LoginService.editUser(user).then((x) => editCallback(x, keys));
        } catch (e) {
            api.error({
                message: `Błąd`,
                description: 'Wystąpił błąd podczas aktualizwania profilu!',
                placement: 'top'
            });
        }
    }

    const updatePassword = () => {
        if(passwords.password !== passwords.passwordRepeat) {
            api.error({
                message: `Błąd`,
                description: 'Podane hasła nie są takie same!',
                placement: 'top'
            });
            return;
        }

        LoginService.updatePassword(passwords.password).then((x) => {
            if ("error" in x) {
                api.error({
                    message: `Błąd`,
                    description: x.error,
                    placement: 'top'
                });
                return;
            }

            api.info({
                message: `Zaktualizowano hasło`,
                description: `Twoje hasło zostało zmienione, możesz się teraz logować używając nowego hasła!`,
                placement: 'top'
            });
        });
    }

    const editCallback = (x: any, keys: string[]) => {
        if ("error" in x) {
            api.error({
                message: `Błąd`,
                description: x.error,
                placement: 'top'
            });
            return;
        }

        api.info({
            message: `Zaktualizowano profil`,
            description: `Dane Twojego profilu zostały zaktualizowane!`,
            placement: 'top'
        });
    }

    useEffect(() => {
        LoginService.getUser().then((x) => {
            if ("error" in x) {
                api.error({
                    message: `Błąd`,
                    description: x.error,
                    placement: 'top'
                });
                return;
            }

            setForm({
                firstName: x.firstName,
                lastName: x.lastName,
                holidayAllowance: x.holidayAllowance,
                organisation: x.organisation,
                position: x.position,
                email: x.email,
                scopes: x.scopes,
                avatar: x.avatar
            });
        });
    }, []);

    return (
        <div className="profile">
            {contextHolder}
            <Card>
                <Skeleton avatar active loading={false}>
                    <Meta
                        avatar={<InfoCircleTwoTone style={{fontSize: '2rem'}}/>}
                        title="Ustawienia użytkownika"
                        description={<div>
                            <p>W tej skecji możesz zmienić ustawienia swojego konta.</p>
                            <p>Ustawienia, które mogą zostąć zmienione to dane personalne, hasło i preferencje.</p>
                        </div>}
                    />
                </Skeleton>
            </Card>
            <h3>Zmień swoje dane</h3>
            <div className={'user-add__form-row'}>
                <div className={'user-add__form-field-wrapper'}>
                    <label>Imię <b>*</b></label>
                    <Input size="large" placeholder="Jan" value={form['firstName']}
                           onChange={(e: any) => updateForm(e, 'firstName')} className={'user-add__form-field'}
                           required/>
                </div>
                <div className={'user-add__form-field-wrapper'}>
                    <label>Nazwisko <b>*</b></label>
                    <Input size="large" placeholder="Nowak" value={form['lastName']}
                           onChange={(e: any) => updateForm(e, 'lastName')} className={'user-add__form-field'}/>
                </div>
            </div>
            <div className={'user-add__form-row'}>
                <div className={'user-add__form-field-wrapper'}>
                    <label>Dział <b>*</b></label>
                    <Input size="large" placeholder="Administracja" value={form['organisation']}
                           onChange={(e: any) => updateForm(e, 'organisation')} className={'user-add__form-field'}/>
                </div>
                <div className={'user-add__form-field-wrapper'}>
                    <label>Stanowisko <b>*</b></label>
                    <Input size="large" placeholder="Kierownik działu..." value={form['position']}
                           onChange={(e: any) => updateForm(e, 'position')} className={'user-add__form-field'}/>
                </div>
            </div>
            <div className={'user-add__form-row'}>
                <div className={'user-add__form-field-wrapper'}>
                    <label>Adres email <b>*</b></label>
                    <Input size="large" placeholder="jan@nowak.pl" value={form['email']}
                           onChange={(e: any) => updateForm(e, 'email')} className={'user-add__form-field'}/>
                </div>
                <div className={'user-add__form-field-wrapper'}>
                    <label>Liczba dni urlopu (w roku) <b>*</b></label>
                    <Input size="large" type={'number'} placeholder="25" value={form['holidayAllowance']}
                           onChange={(e: any) => updateForm(e, 'holidayAllowance')} className={'user-add__form-field'}/>
                </div>
            </div>
            <div className={'user-add__form-row'}>
                <div className={'user-add__form-field-wrapper'}>
                    <label>Link do zdjęcia</label>
                    <Input size="large" placeholder="https://..." value={form['avatar']}
                           onChange={(e: any) => updateForm(e, 'avatar')} className={'user-add__form-field'}/>
                </div>
            </div>
            <Button type={'primary'} onClick={() => publish()} className={'user-add__button'}>Zapisz</Button>
            <h3>Zmień hasło</h3>
            <div className={'user-add__form-row'}>
                <div className={'user-add__form-field-wrapper'}>
                    <label>Hasło</label>
                    <Input size="large" placeholder="Hasło..." value={passwords.password} type={'password'}
                           onChange={(e: any) => setPasswords({...passwords, password: e.target.value})} className={'user-add__form-field'}/>
                </div>
                <div className={'user-add__form-field-wrapper'}>
                    <label>Powtórz hasło</label>
                    <Input size="large" placeholder="Powtórz hasło..." value={passwords.passwordRepeat} type={'password'}
                           onChange={(e: any) =>  setPasswords({...passwords, passwordRepeat: e.target.value})} className={'user-add__form-field'}/>
                </div>
            </div>
            <Button type={'primary'} onClick={() => updatePassword()} className={'user-add__button'}>Zapisz</Button>
        </div>
    );
}

export default Profile;
