import {BACKEND_BASE, GetToken, Logout} from "./LoginService";
import {HolidayEntry} from "../screens/holiday/Holiday";
import {HolidayStatus} from "../extensions/DateFormatter";

export const HolidayService = {
    getAllHoliday: async (): Promise<HolidayEntry[]> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Holiday`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if(response.status === 401) {
                throw new Error('unauthorized');
            }

            const data = await response.json();
            data.sort((a: HolidayEntry, b: HolidayEntry) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());
            return data;
        } catch (e) {
            Logout();
            throw new Error('unauthorized');
        }
    },
    addHoliday: async (holiday: HolidayEntry): Promise<HolidayEntry> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Holiday`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(holiday)
            });

            if(response.status === 401) {
                throw new Error('unauthorized');
            }

            return await response.json();
        } catch (e) {
            Logout();
            throw new Error('unauthorized');
        }
    },
    updateHolidayStatus: async (id: string, status: HolidayStatus): Promise<boolean> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Holiday/${id}/${status}`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if(response.status === 401) {
                Logout();
                return false;
            }

            return true;
        } catch {
            return false;
        }
    },
    downloadForm: (id: string): void => {
        fetch(`${BACKEND_BASE}/Holiday/PDF/${id}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': BACKEND_BASE,
                'Access-Control-Allow-Credentials': 'true',
                'Authorization': 'Bearer ' + GetToken()
            }
        })
            .then(response => response.blob())
            .then(result => {
                const file = window.URL.createObjectURL(result);
                window.open(file, '_blank');
            })
            .catch(error => console.log('error', error));
    }
}
