import React, {useEffect} from 'react';
import {Tag} from "antd";
import './TagCloud.css';

export interface TagCloudProps {
    tags: string[];
    limit: number;
}

export default function TagCloud({tags, limit}: TagCloudProps) {
    const [preTags, setPreTags] = React.useState([] as string[]);
    const [postTags, setPostTags] = React.useState([] as string[]);
    const [displayMore, setDisplayMore] = React.useState(false);

    useEffect(() => {
        setPreTags(tags.slice(0, limit));
        setPostTags(tags.slice(limit, tags.length));
    }, []);

    return (
        <div className="tag-cloud">
            {preTags.length > 0 && preTags.map(x => <Tag key={`pre-${x}`}>{x}</Tag>)}
            {postTags.length > 0 &&
                <div onMouseEnter={() => setDisplayMore(true)}
                     onMouseLeave={() => setDisplayMore(false)}
                     className={'tag-cloud__container'}>
                    <Tag key={'post-length'}>{postTags.length}+</Tag>
                    {displayMore && <div className={'tag-cloud__more'}>
                        {postTags.map(x => <Tag key={`post-${x}`}>{x}</Tag>)}
                    </div>}
                </div>}
            {preTags.length === 0 && postTags.length === 0 && '-'}
        </div>
    );
}
