import {Card, List, Button, Typography} from 'antd';

const {Title, Paragraph} = Typography;

const materials = [
    {
        id: 1,
        title: 'Kampania informacyjna o higienie pracy',
        url: 'https://www.praca.gov.pl/-/kampania-informacyjna-o-higienie-pracy',
        description: 'Strona internetowa Ministerstwa Pracy i Polityki Społecznej z informacjami o kampanii informacyjnej dotyczącej higieny pracy.',
    },
    {
        id: 2,
        title: 'Bezpieczeństwo i higiena pracy w czasie pandemii COVID-19',
        url: 'https://www.parp.gov.pl/component/content/article/55-bezpieczenstwo-i-higiena-pracy-w-czasie-pandemii-covid-19/2025-bezpieczenstwo-i-higiena-pracy-w-czasie-pandemii-covid-19',
        description: 'Strona Polskiej Agencji Rozwoju Przedsiębiorczości z informacjami o bezpieczeństwie i higienie pracy w czasie pandemii COVID-19.',
    },
    {
        id: 3,
        title: 'Poradnik dla pracodawców w zakresie bezpieczeństwa i higieny pracy',
        url: 'https://www.ciop.pl/CIOPPortalWAR/appmanager/ciop/pl?_nfpb=true&_pageLabel=P1510020969130469315561',
        description: 'Poradnik Centrum Innowacji i Transferu Technologii z praktycznymi wskazówkami dla pracodawców w zakresie bezpieczeństwa i higieny pracy.',
    },
    {
        id: 9,
        title: "Jak uniknąć wypadków w pracy",
        description: "Artykuł zawiera wskazówki dotyczące bezpiecznego zachowania się na stanowisku pracy.",
        link: "https://www.pracuj.pl/pracodawca/artykuly/jak-uniknac-wypadkow-w-pracy",
    },
    {
        id: 10,
        title: "Higiena pracy biurowej",
        description: "Wskazówki dotyczące higieny pracy w biurze.",
        link: "https://www.pracuj.pl/pracodawca/artykuly/higiena-pracy-biurowej",
    },
    {
        id: 11,
        title: "Jak prawidłowo podnosić ciężary",
        description: "Artykuł zawiera informacje na temat bezpiecznego podnoszenia ciężarów.",
        link: "https://www.pracuj.pl/pracodawca/artykuly/jak-prawidlowo-podnosic-ciezy",
    },
    {
        id: 4,
        title: "Bezpieczna praca z komputerem",
        description: "Wskazówki dotyczące bezpiecznej pracy z komputerem.",
        link: "https://www.pracuj.pl/pracodawca/artykuly/bezpieczna-praca-z-komputerem",
    },
    {
        id: 5,
        title: "Bezpieczeństwo w pracy na wysokościach",
        description: "Artykuł zawiera informacje na temat bezpiecznej pracy na wysokościach.",
        link: "https://www.pracuj.pl/pracodawca/artykuly/bezpieczenstwo-w-pracy-na-wysokosciach",
    },
    {
        id: 6,
        title: "Zdrowie psychiczne w pracy",
        description: "Artykuł zawiera informacje na temat dbania o zdrowie psychiczne w pracy.",
        link: "https://www.pracuj.pl/pracodawca/artykuly/zdrowie-psychiczne-w-pracy",
    },
    {
        id: 7,
        title: "Bezpieczna praca z narzędziami ręcznymi",
        description: "Wskazówki dotyczące bezpiecznej pracy z narzędziami ręcznymi.",
        link: "https://www.pracuj.pl/pracodawca/artykuly/bezpieczna-praca-z-narzedziami-recznymi",
    },
    {
        id: 8,
        title: "Higiena pracy w magazynie",
        description: "Wskazówki dotyczące higieny pracy w magazynie.",
        link: "https://www.pracuj.pl/pracodawca/artykuly/higiena-pracy-w-magazynie",
    },
];

const Safety = () => {
        return (
            <>
                <Title level={4}>Bezpieczeństwo i higiena pracy</Title>
                <List
                    itemLayout="vertical"
                    dataSource={materials}
                    renderItem={material => (
                        <List.Item key={`safety-${material.id}`}>
                            <List.Item.Meta title={material.title} description={material.description}/>
                            <Button type="primary" href={material.url} target="_blank">
                                Przejdź do materiału
                            </Button>
                        </List.Item>
                    )}
                />
            </>
        );
    }
;

export default Safety;