import {createBrowserRouter} from "react-router-dom";
import News from "../screens/news/News";
import Software from "../screens/software/Software";
import Delegations from "../screens/delegations/Delegations";
import Holiday from "../screens/holiday/Holiday";
import Mail from "../screens/mail/Mail";
import Training from "../screens/training/Training";
import NewsAdd from "../screens/newsadd/NewsAdd";
import SoftwareAdd from "../screens/softwareadd/SoftwareAdd";
import NewsRedaction from "../screens/newsredaction/NewsRedaction";
import Profile from "../screens/profile/Profile";
import Users from "../screens/users/Users";
import UserAdd from "../screens/useradd/UserAdd";
import System from "../screens/system/System";
import TrainingAdd from "../screens/trainingadd/TrainingAdd";
import TrainingRedaction from "../screens/trainingredaction/TrainingRedaction";
import TrainingView from "../screens/trainingview/TrainingView";
import AiAssistant from "../screens/aiassistant/AiAssistant";
import Modules from "../screens/modules/Modules";
import Info from "../screens/info/Info";

const Router = createBrowserRouter([
    {
        path: "/",
        element: <News/>,
    },
    {
        path: "/news/new",
        element: <NewsAdd/>,
    },
    {
        path: "/news/manage",
        element: <NewsRedaction/>,
    },
    {
        path: "/software",
        element: <Software/>,
    },
    {
        path: "/info",
        element: <Info/>,
    },
    {
        path: "/software/new",
        element: <SoftwareAdd/>,
    },
    {
        path: "/delegations",
        element: <Delegations/>,
    },
    {
        path: "/aiassistant",
        element: <AiAssistant/>,
    },
    {
        path: "/holiday",
        element: <Holiday/>,
    },
    {
        path: "/mail",
        element: <Mail/>,
    },
    {
        path: "/modules",
        element: <Modules/>,
    },
    {
        path: "/training/new",
        element: <TrainingAdd/>,
    },
    {
        path: "/training/manage",
        element: <TrainingRedaction/>,
    },
    {
        path: "/training/view/:id",
        element: <TrainingView />,
    },
    {
        path: "/training",
        element: <Training/>,
    },
    {
        path: "/profile",
        element: <Profile/>,
    },
    {
        path: "/users",
        element: <Users/>,
    },
    {
        path: "/users/new",
        element: <UserAdd />,
    },
    {
        path: "/users/edit/:userEmail",
        element: <UserAdd isEdit />,
    },
    {
        path: "/settings",
        element: <System />,
    },
]);

export default Router;
