import React, {useEffect, useState} from 'react';
import './Users.css';
import {UserService} from "../../services/UserService";
import {Button, Descriptions, Drawer, Empty, Input, notification, Popconfirm, Space, Table, Tag, Tooltip} from "antd";
import {ColumnsType} from "antd/es/table";
import {CloseOutlined, EditOutlined} from "@ant-design/icons";
import {User} from "../../types/AuthState";
import TagCloud from "../../components/tagcloud/TagCloud";
import {useAuth} from "../../extensions/Auth";
import {AdminScopes} from "../../types/Scopes";
import UserDrawer from "../../components/userdrawer/UserDrawer";
import UserImportModal from "../../components/userimportmodal/UserImportModal";

export const ScopeMapper = (value: number) => {
    switch (value) {
        case 1:
            return 'Zarządzanie systemem';
        case 2:
            return 'Zarządzanie aktualnościami';
        case 4:
            return 'Zarządzanie użytkownikami';
        case 8:
            return 'Zarządzanie oprogramowaniem';
        case 16:
            return 'Zarządzanie poradnikami';
        default:
            return 'Nieznany';
    }
}

const searchFilter = (x: User, searchTerm: string) => {
    return x.name.toLowerCase().includes(searchTerm) ||
        x.position.toLowerCase().includes(searchTerm) ||
        x.email.toLowerCase().includes(searchTerm);
}

function Users() {
    const [userList, setUserList] = useState([] as User[]);
    const [filteredUserList, setFilteredUserList] = useState([] as User[]);
    const [search, setSearch] = useState('');
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState({} as User);
    const [userImportModalOpen, setUserImportModalOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const auth = useAuth();

    const deleteUser = (email: string) => {
        UserService.deleteUser(email).then((x) => {
            if ("error" in x) {
                api.error({
                    message: `Błąd`,
                    description: x.error,
                    placement: 'top'
                });
                return;
            }

            const newList = filteredUserList.filter(x => x.email !== email);
            setFilteredUserList(newList);
            setUserList(userList.filter(x => x.email !== email));

            api.info({
                message: `Użytkownik usunięty`,
                description: `Użytkownik ${email} został usunięty z systemu`,
                placement: 'top'
            });
        });
    }

    const columns: ColumnsType<User> = [
        {
            title: 'Imię',
            dataIndex: 'firstName',
            key: 'firstName',
            render: (value, record) => <span onClick={() => openDrawer(record)}>{value}</span>
        },
        {
            title: 'Nazwisko',
            dataIndex: 'lastName',
            key: 'lastName',
            render: (value, record) => <span onClick={() => openDrawer(record)}>{value}</span>
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (value, record) => <span onClick={() => openDrawer(record)}>{value}</span>
        },
        {
            title: 'Dział',
            dataIndex: 'organisation',
            key: 'organisation',
            responsive: ['md'],
            render: (value, record) => <span onClick={() => openDrawer(record)}>{value}</span>
        },
        {
            title: 'Stanowisko',
            dataIndex: 'position',
            key: 'position',
            responsive: ['md'],
            render: (value, record) => <span onClick={() => openDrawer(record)}>{value}</span>
        },
        {
            title: 'Uprawnienia',
            dataIndex: 'scopes',
            key: 'scopes',
            responsive: ['md'],
            render: (values: number[]) => <TagCloud tags={values.map(x => ScopeMapper(x))} limit={3}/>,
        },
        {
            title: 'Akcje',
            key: 'action',
            responsive: ['lg'],
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip title="Edytuj użytkownika">
                        <EditOutlined onClick={() => auth.navigate(`/users/edit/${record.email}`)}/>
                    </Tooltip>
                    <Tooltip title="Usuń użytkownika">
                        <Popconfirm
                            placement="top"
                            title={`Czy jesteś pewien?`}
                            description={`Czy na pewno chcesz usunąć użytkownika ${record.name}?`}
                            onConfirm={() => deleteUser(record.email)}
                            okText="Tak"
                            cancelText="Nie"
                        >
                            <CloseOutlined onClick={() => null}/>
                        </Popconfirm>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const searchUsers = (value: any, fromString = false) => {
        const searchTerm = fromString ? value.toLowerCase() : value.target.value.toLowerCase()

        setSearch(searchTerm);

        if (searchTerm === '')
            setFilteredUserList(userList);
        else
            setFilteredUserList(userList.filter(x => searchFilter(x, searchTerm)));
    }

    const openDrawer = (record: User) => {
        setDrawerOpen(true);
        setSelectedUser(record);
    }

    const closeDrawer = () => {
        setDrawerOpen(false)
        setSelectedUser({} as User)
    }

    const refreshUsers = async () => {
        const users = await UserService.getUsers()

        setUserList(users);
        setFilteredUserList(users.filter(x => searchFilter(x, search)));
    }

    useEffect(() => {
        auth.verifyAuth(AdminScopes.USERS);

        UserService.getUsers().then(x => {
            setUserList(x);
            setFilteredUserList(x);
        });
    }, []);

    return (
        <div className="users">
            {contextHolder}
            <div className={'users__header'}>
                <Space className={'users__new'}>
                    <Button type={'primary'} ghost onClick={() => auth.navigate('/users/new')}>Dodaj</Button>
                    <Button type={'primary'} ghost onClick={() => setUserImportModalOpen(true)}>Importuj</Button>
                </Space>
                <div className={'users__search-wrapper'}>
                    <Input.Search placeholder="Wyszukaj użytkownika" allowClear size={'large'} onChange={(value: any) => searchUsers(value)}
                                  className={'users__search'}/>
                </div>
                <div className={'users__new'}></div>
            </div>
            <Table columns={columns} dataSource={filteredUserList} rowKey={'id'}
                   locale={{emptyText: <Empty description={'Brak danych'}/>}}/>
            <UserDrawer closeDrawer={closeDrawer} drawerOpen={drawerOpen} selectedUser={selectedUser} />
            <UserImportModal open={userImportModalOpen} setOpen={setUserImportModalOpen} refreshUsers={refreshUsers} />
        </div>
    );
}

export default Users;
