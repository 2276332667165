import { Table, Typography } from 'antd';
import {ReactElement} from "react";

const { Title } = Typography;

const updates = [
    {
        key: '1',
        version: '1.0.0',
        date: '01.01.2023',
        changes: [
            'Pierwsza wersja aplikacji',
            'Dodanie ekranu logowania',
            'Dodanie ekranu listy klientów',
        ],
    },
    {
        key: '2',
        version: '1.1.0',
        date: '15.01.2023',
        changes: [
            'Poprawki w interfejsie użytkownika',
            'Dodanie funkcjonalności filtrowania i sortowania w liście klientów',
            'Dodanie możliwości usuwania klientów',
        ],
    },
    {
        key: '3',
        version: '1.2.0',
        date: '01.02.2023',
        changes: [
            'Dodanie ekranu dodawania klientów',
            'Poprawki w działaniu filtrowania i sortowania w liście klientów',
            'Poprawki w interfejsie użytkownika',
        ],
    },
];

const columns = [
    {
        title: 'Wersja',
        dataIndex: 'version',
        key: 'version',
    },
    {
        title: 'Data',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: 'Zmiany',
        dataIndex: 'changes',
        key: 'changes',
        render: (text: any) => (
            <ul>
                {text.map((change: string) => (
                    <li key={change}>{change}</li>
                ))}
            </ul>
        ),
    },
];

const Updates = () => {
    return (
        <>
            <Title level={4}>Rejestr zmian</Title>
            <Table dataSource={updates} columns={columns} pagination={false} />
        </>
    );
};

export default Updates;