import React, {useEffect, useState} from 'react';
import './TrainingView.css';
import {useParams} from "react-router-dom";
import {TrainingService} from "../../services/TrainingService";
import {NewsCardProps} from "../../components/newscard/NewsCard";
import DateFormatter from "../../extensions/DateFormatter";
import {Card, Spin} from "antd";
import {useAuth} from "../../extensions/Auth";

function TrainingView() {
    const auth = useAuth();
    const [data, setData] = useState({} as NewsCardProps);
    const [loading, setLoading] = useState(true);

    let {id} = useParams();

    useEffect(() => {
        setLoading(true);

        if (!id) {
            auth.navigate('/training')
            return
        }

        TrainingService.getTrainingCours(id).then((r) => {
            setData(r);
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
        });
    }, []);
    return (
        <div className="training-view">
            {!loading && <Card className={'training-view__content'}>
                <h1>{data.title}</h1>
                <p className={'training-view__content__short'}>{data.shortDescription}</p>
                <p dangerouslySetInnerHTML={{__html: data.description}}></p>
                <span style={{
                    lineHeight: '14px',
                    height: '14px',
                    verticalAlign: 'middle',
                    textAlign: 'right',
                    width: '100%',
                    display: 'block'
                }}>{data.author}, {DateFormatter(data.date)}</span>
            </Card>}
            {loading && <Spin tip="Ładowanie..." size="large" className={'loader'}/>}

        </div>
    );
}

export default TrainingView;
