import {Row, Col, Card, Typography, Divider, Table} from 'antd';

const { Title } = Typography;

const trainings = [
    {
        key: 'training-1',
        title: 'Bezpieczeństwo w pracy',
        date: '15.05.2023',
        description: 'Szkolenie z zakresu bezpieczeństwa i higieny pracy.',
        trainer: 'Anna Kowalska',
        actions: <a>Więcej informacji</a>
    },
    {
        key: 'training-2',
        title: 'Komunikacja z klientem',
        date: '17.05.2023',
        description: 'Szkolenie z zakresu komunikacji interpersonalnej i obsługi klienta.',
        trainer: 'Jan Nowak',
        actions: <a>Więcej informacji</a>
    },
    {
        key: 'training-3',
        title: 'Zarządzanie stresem',
        date: '20.05.2023',
        description: 'Szkolenie z zakresu radzenia sobie ze stresem i zapobiegania wypaleniu zawodowemu.',
        trainer: 'Maria Nowakowska',
        actions: <a>Więcej informacji</a>
    },
    {
        key: 'training-4',
        title: 'Negocjacje',
        date: '25.05.2023',
        description: 'Szkolenie z zakresu negocjacji biznesowych.',
        trainer: 'Piotr Wiśniewski',
        actions: <a>Więcej informacji</a>
    },
    {
        key: 'training-5',
        title: 'Zarządzanie czasem',
        date: '27.05.2023',
        description: 'Szkolenie z zarządzania czasem i planowania zadań.',
        trainer: 'Katarzyna Szymańska',
        actions: <a>Więcej informacji</a>
    },
    {
        key: 'training-6',
        title: 'Efektywne prezentacje',
        date: '29.05.2023',
        description: 'Szkolenie z zakresu tworzenia i prowadzenia efektywnych prezentacji.',
        trainer: 'Jan Kowalski',
        actions: <a>Więcej informacji</a>
    },
    {
        key: 'training-7',
        title: 'Zarządzanie projektami',
        date: '02.06.2023',
        description: 'Szkolenie z zarządzania projektami i narzędzi pracy w zespole.',
        trainer: 'Anna Nowak',
        actions: <a>Więcej informacji</a>
    },
    {
        key: 'training-8',
        title: 'Rozwiązywanie problemów',
        date: '05.06.2023',
        description: 'Szkolenie z zakresu kreatywnego myślenia i rozwiązywania problemów.',
        trainer: 'Mariusz Kowalczyk',
        actions: <a>Więcej informacji</a>
    },
    {
        key: 'training-9',
        title: 'Marketing internetowy',
        date: '08.06.2023',
        description: 'Szkolenie z zakresu marketingu internetowego i pozycjonowania stron.',
        trainer: 'Katarzyna Wiśniewska',
        actions: <a>Więcej informacji</a>
    }
];

const columns = [
    {
        title: 'Nazwa szkolenia',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Data',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: 'Opis',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Trener',
        dataIndex: 'trainer',
        key: 'trainer',
    },
    {
        title: 'Akcje',
        dataIndex: 'actions',
        key: 'actions'
    }
];


const TrainingCourses = () => {
    return (
        <div>
            <Title level={4}>Szkolenia</Title>
            <Table dataSource={trainings} columns={columns} pagination={false} />
        </div>
    );
};

export default TrainingCourses;