import React, {useState} from 'react';
import './TrainingAdd.css';
import {useAuth} from "../../extensions/Auth";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {InfoCircleTwoTone} from "@ant-design/icons";
import {EditorState} from "draft-js";
import {Button, Card, Input, notification, Avatar, Skeleton, Switch} from "antd";
import {convertToHTML} from 'draft-convert';
import {TrainingService} from "../../services/TrainingService";
import TextArea from "antd/es/input/TextArea";

const {Meta} = Card;

function TrainingAdd() {
    const auth = useAuth();
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    const [api, contextHolder] = notification.useNotification();
    const [title, setTitle] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [description, setDescription] = useState('');

    const updateTitle = (e: any) => {
        setTitle(e.target.value);
    }

    const updateShortDescription = (e: any) => {
        setShortDescription(e.target.value);
    }

    const updateEditorState = (editorState: EditorState) => {
        const description = convertToHTML(editorState.getCurrentContent());
        setEditorState(editorState);
        setDescription(description);
    }

    const publish = () => {
        if (!title || !description || !shortDescription) {
            api.error({
                message: `Uzupełnij tytuł, skrót i treść`,
                description: 'Tytuł, skrót i treść nie mogą być puste!',
                placement: 'top'
            });
            return;
        }

        if (title.length > 70) {
            api.error({
                message: `Zbyt długi tytuł`,
                description: 'Tytuł może mieć maksymalnie 70 znaków!',
                placement: 'top'
            });
            return;
        }

        const data = {
            title,
            description,
            shortDescription
        }

        try {
            TrainingService.addTraining(data).then(() => {
                api.info({
                    message: `Dodano poradnik`,
                    description: 'Poradnik został dodany!',
                    placement: 'top'
                });
                setTitle('');
                setDescription('');
                setShortDescription('');
                setEditorState(EditorState.createEmpty());
            });
        } catch (e) {
            api.error({
                message: `Błąd`,
                description: 'Wystąpił błąd podczas dodawania poradnika!',
                placement: 'top'
            });
        }
    }

    return (
        <div className="training">
            {contextHolder}
            <Card className={'training__info'}>
                <Skeleton avatar active loading={false}>
                    <Meta
                        avatar={<InfoCircleTwoTone style={{fontSize: '2rem'}}/>}
                        title="Dodawanie poradników"
                        description="W tej sekcji możesz dodać poradnik dla swoich współpracowników. Po dodaniu, poradnik musi zostać zaakceptowany przez jednego z administratorów zanim pojawi się w sekcji poradników."
                    />
                </Skeleton>
            </Card>
            <Input size="large" placeholder="Tytuł" value={title} onChange={updateTitle} className={'training-title'}
                   maxLength={70}/>
            <TextArea size="large" placeholder="Skrót" value={shortDescription} onChange={updateShortDescription}
                      className={'training-title'} rows={3} maxLength={500}/>
            <Editor
                editorState={editorState}
                toolbarClassName="training-toolbar"
                wrapperClassName="training-wrapper"
                editorClassName="training-editor"
                onEditorStateChange={updateEditorState}
            />
            <Button type={'primary'} onClick={publish}>Dodaj</Button>
        </div>
    );
}

export default TrainingAdd;
