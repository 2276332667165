import {Table, Typography} from 'antd';

const {Title, Paragraph} = Typography;

const columns = [
    {
        title: 'Tytuł artykułu',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Link',
        dataIndex: 'link',
        key: 'link',
        render: (text: string) => <a href={text}>Zobacz artykuł</a>,
    },
];

const data = [
    {
        key: '1',
        title: 'Pierwsza pomoc w przypadku udaru',
        link: 'https://www.medonet.pl/zdrowie/zdrowie-dziecka/pierwsza-pomoc-w-przypadku-udaru--co-robi--jak-pomoc,artykul,39275665.html',
    },
    {
        key: '2',
        title: 'Podstawowe czynności ratunkowe',
        link: 'https://www.gov.pl/web/zdrowie/pierwsza-pomoc',
    },
    {
        key: '3',
        title: 'Jak udzielać pierwszej pomocy przedmedycznej?',
        link: 'https://pomocprzedmedyczna.pl/udzielanie-pierwszej-pomocy/',
    },
];

const FirstAid = () => {
    return (
        <div>
            <Title level={4}>Podstawy pierwszej pomocy</Title>
            <Paragraph>Tu znajdziesz informacje na temat podstawowych czynności ratunkowych.</Paragraph>
            <Table columns={columns} dataSource={data} pagination={false} />
        </div>
    );
};

export default FirstAid;