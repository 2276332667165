import {SoftwareCardProps} from "../components/softwarecard/SoftwareCard";
import {BACKEND_BASE, GetToken, Logout} from "./LoginService";
import {SoftwareRequest} from "../types/SoftwareModels";

export const SoftwareService = {
    getSoftware: async (): Promise<SoftwareCardProps[]> => {
            try {
                const response = await fetch(`${BACKEND_BASE}/Software`, {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': BACKEND_BASE,
                        'Access-Control-Allow-Credentials': 'true',
                        'Authorization': 'Bearer ' + GetToken()
                    }
                });

                if(response.status === 401) {
                    throw new Error('unauthorized');
                }

                return await response.json();
            } catch (e) {
                Logout();
                throw new Error('unauthorized');
            }
    },
    addSoftware: async (software: SoftwareRequest): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Software`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(software)
            });

            if(response.status === 401) {
                throw new Error('unauthorized');
            }

            return await response.json();
        } catch (e) {
            Logout();
            throw new Error('unauthorized');
        }
    }
}
