import React, {useState} from 'react';
import {Avatar, Card, notification, Skeleton, Space, Tooltip} from "antd";
import Meta from "antd/es/card/Meta";
import './NewsCard.css';
import DateFormatter from "../../extensions/DateFormatter";
import {CheckOutlined, CloseOutlined, DeleteOutlined} from "@ant-design/icons";
import {NewsService} from "../../services/NewsService";
import {ErrorObject} from "../../types/AuthState";
import {useAuth} from "../../extensions/Auth";
import {TrainingService} from "../../services/TrainingService";

export interface NewsCardProps {
    id: string;
    title: string;
    description: string;
    shortDescription?: string;
    avatar: string;
    author: string;
    date: string;
    approved: boolean;
    withApproval?: boolean;
    className?: string;
    isTraining?: boolean;
    onClick?: () => void;
}

function NewsCard(props: NewsCardProps) {
    const auth = useAuth();
    const {
        id,
        title,
        description,
        shortDescription,
        avatar,
        author,
        date,
        withApproval,
        approved,
        className,
        isTraining,
        onClick
    } = props;
    const [api, contextHolder] = notification.useNotification();
    const [status, setStatus] = useState(approved);

    const changeNewsStatus = (id: string, status: boolean) => {
        NewsService.changeNewsStatus(id, status).then(() => {
            setStatus(status);
            api.info({
                message: `Status aktualności zmieniony pomyślnie`,
                description: status ? 'Aktualność została zaakceptowana, będzie ona widoczna na stronie głównej' : 'Aktualność została odrzucona, nie będzie ona widoczna na stronie głównej',
                placement: 'top'
            });
        }).catch(() => {
            api.error({
                message: `Błąd`,
                description: 'Wystąpił błąd podczas zmiany statusu!',
                placement: 'top'
            });
        });
    }

    const deleteTraining = (id: string) => {
        TrainingService.deleteTraining(id).then(() => {
            api.info({
                message: `Poradnik usunięty pomyślnie`,
                description: 'Poradnik został usunięty!',
                placement: 'top'
            });
        }).catch((e: any) => {
            if (e.type === 'notFound')
                api.error({
                    message: `Błąd`,
                    description: 'Wystąpił błąd podczas usuwania poradnika!',
                    placement: 'top'
                });
            else
                auth.handleLogout();
        });
    }

    const changeTrainingStatus = (id: string, status: boolean) => {
        TrainingService.changeTrainingStatus(id, status).then(() => {
            setStatus(status);
            api.info({
                message: `Status poradnika zmieniony pomyślnie`,
                description: status ? 'Poradnik został zaakceptowany, będzie on widoczny w sekcji Poradniki' : 'Poradnik został odrzucony, nie będzie on widoczny w sekcji Poradniki',
                placement: 'top'
            });
        }).catch(() => {
            api.error({
                message: `Błąd`,
                description: 'Wystąpił błąd podczas zmiany statusu!',
                placement: 'top'
            });
        });
    }

    const deleteNews = (id: string) => {
        NewsService.deleteNews(id).then(() => {
            api.info({
                message: `Poradnik usunięty pomyślnie`,
                description: 'Poradnik został usunięty!',
                placement: 'top'
            });
        }).catch((e: any) => {
            if (e.type === 'notFound')
                api.error({
                    message: `Błąd`,
                    description: 'Wystąpił błąd podczas usuwania poradnika!',
                    placement: 'top'
                });
            else
                auth.handleLogout();
        });
    }

    return (
        <Card
            className={className ?? 'news__card'}
            style={{cursor: onClick ? 'pointer' : 'default'}}
            onClick={() => onClick ? onClick() : {}}
        >
            {contextHolder}
            <Skeleton loading={false} avatar active>
                <Meta
                    avatar={<Avatar src={avatar} className={'avatar'}/>}
                    title={title}
                    description={<p
                        dangerouslySetInnerHTML={{__html: shortDescription ? shortDescription : description}}></p>}
                />
                <div>
                    {withApproval && <Space size="large" style={{marginTop: '10px', lineHeight: '32px'}}>
                        <span>{status ? 'Zaakceptowany' : 'Oczekujący'}</span>
                        <Tooltip title="Zaakceptuj">
                            <CheckOutlined
                                onClick={() => isTraining ? changeTrainingStatus(id, true) : changeNewsStatus(id, true)}/>
                        </Tooltip>
                        <Tooltip title="Odrzuć">
                            <CloseOutlined
                                onClick={() => isTraining ? changeTrainingStatus(id, false) : changeNewsStatus(id, false)}/>
                        </Tooltip>
                        <Tooltip title="Usuń">
                            <DeleteOutlined onClick={() => isTraining ? deleteTraining(id) : deleteNews(id)}/>
                        </Tooltip>
                    </Space>}
                    <span style={{float: 'right', marginTop: '10px'}}>
                            <Avatar src={avatar} size={32} className={'mobile-only'}/> <span style={{
                        lineHeight: '32px',
                        height: '32px',
                        verticalAlign: 'middle'
                    }}>{author}, {DateFormatter(date)}</span>
                    </span>
                </div>
            </Skeleton>
        </Card>
    );
}

export default NewsCard;
