import React, {useEffect, useState} from 'react';
import './NewsRedaction.css';
import {Button, Pagination, Spin} from "antd";
import NewsCard, {NewsCardProps} from "../../components/newscard/NewsCard";
import {NewsService} from "../../services/NewsService";
import {useAuth} from "../../extensions/Auth";
import {AdminScopes} from "../../types/Scopes";

function NewsRedaction() {
    const auth = useAuth();
    const [page, setPage] = useState(1);
    const [data, setData] = useState([] as NewsCardProps[]);
    const [newsData, setNewsData] = useState([] as NewsCardProps[]);
    const [loading, setLoading] = useState(true);

    const filter = (p = 1, input = data) => setNewsData(input.filter((_, index) => index >= (p - 1) * 10 && index < p * 10));

    useEffect(() => {
        auth.verifyAuth(AdminScopes.NEWS);

        setLoading(true);
        NewsService.getPendingNews().then((r) => {
            setData(r);
            filter(page, r);
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
            auth.handleLogout();
        });
    }, []);

    const updatePagination = (page: number) => {
        setPage(page);
        filter(page);
    }

    return (
        <div className="news-redaction">
            <div className="news-redaction__content">
                {!loading && newsData.map((news, index) => <NewsCard key={index} className={'news-redaction__card'} {...news} withApproval={true} />)}
                {loading && <Spin tip="Ładowanie..." size="large" className={'loader'}/>}
            </div>
            <Pagination defaultCurrent={1} total={data.length} onChange={updatePagination} showSizeChanger={false}/>
        </div>
    );
}

export default NewsRedaction;
