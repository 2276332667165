import React, {useEffect, useState} from 'react';
import './Software.css';
import {Button, Empty, Input, Pagination, Spin} from "antd";
import SoftwareCard, {SoftwareCardProps} from "../../components/softwarecard/SoftwareCard";
import {useAuth} from "../../extensions/Auth";
import {SoftwareService} from "../../services/SoftwareService";
import {AdminScopes} from "../../types/Scopes";

function Software() {
    const auth = useAuth();
    const [page, setPage] = useState(1);
    const [data, setData] = useState([] as SoftwareCardProps[]);
    const [searchFilter, setSearchFilter] = useState('' as string);
    const [softwareData, setSoftwareData] = useState([] as SoftwareCardProps[]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true);

    const filter = (p = 1, f = '', input = data) => {
        const search = input.filter((software) => software.title.toLowerCase().includes(f.toLowerCase()) || software.description.toLowerCase().includes(f.toLowerCase()));
        const pagination = search.filter((_, index) => index >= (p - 1) * 16 && index < p * 16);

        setSoftwareData(pagination);
        setTotal(search.length);
    }

    let searchTimeout = setTimeout(() => {}, 1000);

    const filterBySearch = (event: any) => {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {
            setSearchFilter(event.target.value);
            filter(1, event.target.value, data);
        }, 200);
    }

    useEffect(() => {
        auth.verifyAuth(AdminScopes.SOFTWARE);

        setLoading(true);
        SoftwareService.getSoftware().then((software) => {
            setData(software);
            filter(page, searchFilter, software);
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
            auth.handleLogout();
        });
    }, []);

    const updatePagination = (page: number) => {
        setPage(page);
        filter(page, searchFilter, data);
    }

    return (
        <div className="software">
            <div className={'software__searchbar'}>
                <Input.Search placeholder={'Wpisz frazę do wyszukania'} allowClear size={'large'} onChange={filterBySearch} />
            </div>
            <div className={'software__content'}>
                {!loading && softwareData.map((software, index) => <SoftwareCard key={index} {...software} onClick={software.url ? () => auth.navigate(software.url!, software.url!.startsWith('https')) : () => {}} />)}
                {!loading && softwareData.length === 0 && <Empty description={<span>Brak wyników</span>} />}
                {loading && <Spin tip="Ładowanie..." size="large" className={'loader'}/>}
            </div>
            <Pagination defaultCurrent={1} total={total} onChange={updatePagination} showSizeChanger={false} pageSize={16}/>
        </div>
    );
}

export default Software;
