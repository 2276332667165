import {List, Typography} from 'antd';

const {Title, Paragraph} = Typography;

const RodoScreen = () => {
    return (
        <>
            <Title level={4}>RODO - Ogólne rozporządzenie o ochronie danych</Title>
            <Paragraph>
                Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie
                ochrony
                osób
                fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych
                oraz
                uchylenia
                dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), zwane dalej RODO, jest unijną regulacją w
                zakresie
                ochrony danych osobowych.
            </Paragraph>
            <Title level={5}>Podstawowe pojęcia</Title>
            <ul>
                <li>Dane osobowe - jakiekolwiek informacje dotyczące zidentyfikowanej lub możliwej do zidentyfikowania
                    osoby
                    fizycznej
                </li>
                <li>Administrator - podmiot decydujący o celach i sposobach przetwarzania danych osobowych</li>
                <li>Podmiot danych - osoba, której dane dotyczą</li>
                <li>Procesor danych - podmiot przetwarzający dane osobowe na zlecenie administratora</li>
                <li>Rzetelność i przejrzystość - kluczowe zasady przetwarzania danych osobowych</li>
            </ul>
            <Title level={5}>Linki do przydatnych materiałów na temat RODO:</Title>
            <ul>
                <li><a href="https://eur-lex.europa.eu/legal-content/PL/TXT/HTML/?uri=CELEX:32016R0679&from=EN">Pełny
                    tekst
                    rozporządzenia RODO w języku polskim</a></li>
                <li><a href="https://uodo.gov.pl/pl/138/883">Strona internetowa Urzędu Ochrony Danych Osobowych</a></li>
                <li><a href="https://edpb.europa.eu/edpb_pl">Europejska Rada Ochrony Danych Osobowych</a></li>
            </ul>
        </>
    );
};

export default RodoScreen;
