export default function DateFormatter(date: string) {
    const d = new Date(date);
    return d.toLocaleDateString('pl-PL', {year: 'numeric', month: 'long', day: 'numeric'});
}

export function getEaster(year: number): Date {
    const a = year % 19;
    const b = Math.floor(year / 100);
    const c = year % 100;
    const d = Math.floor(b / 4);
    const e = b % 4;
    const f = Math.floor((b + 8) / 25);
    const g = Math.floor((b - f + 1) / 3);
    const h = (19 * a + b - d - g + 15) % 30;
    const i = Math.floor(c / 4);
    const k = c % 4;
    const l = (32 + 2 * e + 2 * i - h - k) % 7;
    const m = Math.floor((a + 11 * h + 22 * l) / 451);
    const month = Math.floor((h + l - 7 * m + 114) / 31);
    const day = ((h + l - 7 * m + 114) % 31) + 1;

    return new Date(year, month - 1, day);
}

export enum HolidayDayName {
    Saturday = 'Sobota',
    Sunday = 'Niedziela',
    NewYear = 'Nowy Rok',
    Epiphany = 'Trzech Króli',
    Easter = 'Wielkanoc',
    EasterMonday = 'Poniedziałek Wielkanocny',
    LabourDay = 'Święto Pracy',
    ConstitutionDay = 'Święto Konstytucji 3 Maja',
    GreenDays = 'Zesłanie Ducha Świętego',
    CorpusChristi = 'Boże Ciało',
    PolishArmyDay = 'Święto Wojska Polskiego',
    DayOfDead = 'Wszystkich Świętych',
    DayOfNationalUnity = 'Święto Niepodległości',
    Christmas = 'Boże Narodzenie',
    BoxingDay = 'Drugi Dzień Bożego Narodzenia',
    None = 'Brak'
}

export enum HolidayStatus {
    Pending,
    Completed,
    Rejected,
    Accepted
}

export const HolidayDescriptionMap = (value: number) => {
    switch(value) {
        case 0: return 'Oczekuje na akceptację';
        case 1: return 'Wykorzystane';
        case 2: return 'Odrzucone';
        case 3: return 'Zaakceptowane (niedobyte)';
        default: return '';
    }
}

export function isDayOff(day: Date, easterDate: Date): HolidayDayName {
    const easterMonday = new Date(easterDate.getTime() + 86400000);
    const greenDays = new Date(easterDate.getTime() + 86400000 * 60);
    const corpusChristi = new Date(easterDate.getTime() + 86400000 * 61);

    if (day.getDay() === 0) return HolidayDayName.Sunday;
    if (day.getDay() === 6) return HolidayDayName.Saturday;
    if (day.getMonth() + 1 === 1 && day.getDate() === 1) return HolidayDayName.NewYear;
    if (day.getMonth() + 1 === 1 && day.getDate() === 6) return HolidayDayName.Epiphany;
    if (day.getMonth() + 1 === 5 && day.getDate() === 1) return HolidayDayName.LabourDay;
    if (day.getMonth() + 1 === 5 && day.getDate() === 3) return HolidayDayName.ConstitutionDay;
    if (day.getMonth() + 1 === 8 && day.getDate() === 15) return HolidayDayName.PolishArmyDay;
    if (day.getMonth() + 1 === 11 && day.getDate() === 1) return HolidayDayName.DayOfDead;
    if (day.getMonth() + 1 === 11 && day.getDate() === 11) return HolidayDayName.DayOfNationalUnity;
    if (day.getMonth() + 1 === 12 && day.getDate() === 25) return HolidayDayName.Christmas;
    if (day.getMonth() + 1 === 12 && day.getDate() === 26) return HolidayDayName.BoxingDay;
    if (day.getMonth() === easterDate.getMonth() && day.getDate() === easterDate.getDate()) return HolidayDayName.Easter;
    if (day.getMonth() === easterMonday.getMonth() && day.getDate() === easterMonday.getDate()) return HolidayDayName.EasterMonday;
    if (day.getMonth() === greenDays.getMonth() && day.getDate() === greenDays.getDate()) return HolidayDayName.GreenDays;
    if(day.getMonth() === corpusChristi.getMonth() && day.getDate() === corpusChristi.getDate()) return HolidayDayName.CorpusChristi;
    return HolidayDayName.None;
}
