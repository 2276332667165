import React, {useEffect, useState} from 'react';
import './Training.css';
import {useAuth} from "../../extensions/Auth";
import NewsCard, {NewsCardProps} from "../../components/newscard/NewsCard";
import {Button, Input, Pagination, Space, Spin} from "antd";
import {TrainingService} from "../../services/TrainingService";

function Training() {
    const auth = useAuth();
    const [page, setPage] = useState(1);
    const [data, setData] = useState([] as NewsCardProps[]);
    const [trainingData, setTrainingData] = useState([] as NewsCardProps[]);
    const [loading, setLoading] = useState(true);
    const [searchFilter, setSearchFilter] = useState('' as string);
    const [total, setTotal] = useState(0);

    let searchTimeout = setTimeout(() => {}, 1000);

    const filter = (p = 1, f = '', input = data) => {
        const search = input.filter((news) => news.title.toLowerCase().includes(f.toLowerCase()) || news.description.toLowerCase().includes(f.toLowerCase()) || news.shortDescription?.toLowerCase().includes(f.toLowerCase()));
        const pagination = search.filter((_, index) => index >= (p - 1) * 16 && index < p * 16);

        setTrainingData(pagination);
        setTotal(search.length);
    }

    useEffect(() => {
        setLoading(true);
        TrainingService.getTrainingCourses().then((r) => {
            setData(r);
            filter(page, searchFilter, r);
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
            auth.handleLogout();
        });
    }, []);

    const updatePagination = (page: number) => {
        setPage(page);
        filter(page);
    }

    const filterBySearch = (event: any) => {
        clearTimeout(searchTimeout);
        setTimeout(() => {
            setSearchFilter(event.target.value);
            filter(1, event.target.value, data);
        }, 200);
    }

    return (
        <div className="training">
            <div className={'training__searchbar'}>
                <Button type={'primary'} ghost onClick={() => auth.navigate('/training/new')} className={'training__add__button'}>Dodaj</Button>
                <Input.Search placeholder={'Wpisz frazę do wyszukania'} allowClear size={'large'} onChange={filterBySearch} />
            </div>
            <div className="training__content">
                {!loading && trainingData.map((news, index) => <NewsCard key={index} {...news} className={'training__card'} onClick={() => auth.navigate(`/training/view/${news.id}`)} />)}
                {loading && <Spin tip="Ładowanie..." size="large" className={'loader'}/>}
            </div>
            <Pagination defaultCurrent={1} total={data.length} onChange={updatePagination} showSizeChanger={false}/>
        </div>
    );
}

export default Training;
