import React from 'react';
import './Modules.css';
import {Card, Col, Row} from "antd";
import {
    HomeOutlined,
    ShoppingCartOutlined,
    CodeOutlined,
    BellOutlined,
    ProjectOutlined,
    CarOutlined,
    GlobalOutlined
} from "@ant-design/icons";
import {useAuth} from "../../extensions/Auth";

function Modules() {
    const auth = useAuth();
    const colSize = 6;

    return (
        <div className="modules">
            <Row gutter={[16, 24]}>
                <Col className="modules__col" span={colSize}>
                    <Card
                        hoverable
                        bordered
                        onClick={() => auth.navigate('/holiday')}
                        className={'modules__card'}
                    >
                        <HomeOutlined className={'modules__card-icon'}/>
                        <h1>Urlop</h1>
                        <h3>Generator wniosków urlopowych</h3>
                    </Card>
                </Col>
                <Col className="modules__col" span={colSize}>
                    <Card
                        hoverable
                        bordered
                        onClick={() => auth.navigate('/software')}
                        className={'modules__card'}
                    >
                        <CodeOutlined className={'modules__card-icon'}/>
                        <h1>Oprogramowanie</h1>
                        <h3>Baza przydatnych programów</h3>
                    </Card>
                </Col>
                <Col className="modules__col" span={colSize}>
                    <Card
                        hoverable
                        bordered
                        onClick={() => auth.navigate('/software')}
                        className={'modules__card'}
                    >
                        <BellOutlined className={'modules__card-icon'}/>
                        <h1>Sygnalizer</h1>
                        <h3>Anonimowe zgłoszenie nieprawidłowości</h3>
                    </Card>
                </Col>
                <Col className="modules__col" span={colSize}>
                    <Card
                        hoverable
                        bordered
                        onClick={() => auth.navigate('/software')}
                        className={'modules__card'}
                    >
                        <ProjectOutlined className={'modules__card-icon'}/>
                        <h1>Wekan</h1>
                        <h3>Organizacja pracy</h3>
                    </Card>
                </Col>
                <Col className="modules__col" span={colSize}>
                    <Card
                        hoverable
                        bordered
                        onClick={() => auth.navigate('/software')}
                        className={'modules__card'}
                    >
                        <GlobalOutlined className={'modules__card-icon'}/>
                        <h1>Geotool</h1>
                        <h3>Dostępny wkrótce</h3>
                    </Card>
                </Col>
                <Col className="modules__col" span={colSize}>
                    <Card
                        hoverable
                        bordered
                        onClick={() => auth.navigate('/delegations')}
                        className={'modules__card'}
                    >
                        <CarOutlined className={'modules__card-icon'}/>
                        <h1>Delegacje</h1>
                        <h3>Dostępny wkrótce</h3>
                    </Card>
                </Col>
                <Col className="modules__col" span={colSize}>
                    <Card
                        hoverable
                        bordered
                        onClick={() => auth.navigate('/software')}
                        className={'modules__card'}
                    >
                        <ShoppingCartOutlined className={'modules__card-icon'}/>
                        <h1>Zamówienia</h1>
                        <h3>Dostępny wkrótce</h3>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default Modules;
