import React from 'react';
import './Mail.css';

function Mail() {
    return (
        <div className="News">
            <h1>Mail</h1>
        </div>
    );
}

export default Mail;
