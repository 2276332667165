import React, {useState} from 'react';
import './NewsAdd.css';
import {useAuth} from "../../extensions/Auth";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {InfoCircleTwoTone} from "@ant-design/icons";
import {EditorState} from "draft-js";
import {Button, Card, Input, notification, Avatar, Skeleton, Switch} from "antd";
import {convertToHTML} from 'draft-convert';
import {NewsService} from "../../services/NewsService";

const { Meta } = Card;

function NewsAdd() {
    const auth = useAuth();
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    const [api, contextHolder] = notification.useNotification();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const updateTitle = (e: any) => {
        setTitle(e.target.value);
    }

    const updateEditorState = (editorState: EditorState) => {
        const description = convertToHTML(editorState.getCurrentContent());
        setEditorState(editorState);
        setDescription(description);
    }

    const publish = () => {
        if (!title || !description) {
            api.error({
                message: `Uzupełnij tytuł i treść`,
                description: 'Tytuł i treść nie mogą być puste!',
                placement: 'top'
            });
            return;
        }

        if(title.length > 70) {
            api.error({
                message: `Zbyt długi tytuł`,
                description: 'Tytuł może mieć maksymalnie 70 znaków!',
                placement: 'top'
            });
            return;
        }

        const data = {
            title,
            description
        }

        try {
            NewsService.addNews(data).then(() => {
                api.info({
                        message: `Dodano aktualność`,
                        description: 'Aktualność została dodana!',
                        placement: 'top'
                    });
                setTitle('');
                setDescription('');
                setEditorState(EditorState.createEmpty());
            });
        } catch (e) {
            api.error({
                message: `Błąd`,
                description: 'Wystąpił błąd podczas dodawania aktualności!',
                placement: 'top'
            });
        }
    }

    return (
        <div className="news">
            {contextHolder}
            <Card className={'news__info'}>
                <Skeleton avatar active loading={false}>
                    <Meta
                        avatar={<InfoCircleTwoTone style={{fontSize: '2rem'}} />}
                        title="Dodawanie aktualności"
                        description="W tej sekcji możesz dodać aktualność dla swoich współpracowników. Po dodaniu, aktualność musi zostać zaakceptowana przez jednego z administratorów zanim pojawi się na stronie głównej."
                    />
                </Skeleton>
            </Card>
            <Input size="large" placeholder="Tytuł" value={title} onChange={updateTitle} className={'news-title'}/>
            <Editor
                editorState={editorState}
                toolbarClassName="news-toolbar"
                wrapperClassName="news-wrapper"
                editorClassName="news-editor"
                onEditorStateChange={updateEditorState}
            />
            <Button type={'primary'} onClick={publish}>Dodaj</Button>
        </div>
    );
}

export default NewsAdd;
