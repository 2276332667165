import {Table, Typography} from 'antd';

const {Title, Paragraph} = Typography;

const columns = [
    {
        title: 'Nazwa',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Kwota',
        dataIndex: 'amount',
        key: 'amount',
        render: (text: string) => `${text} zł`,
    },
];

const data = [
    {
        key: '1',
        name: 'Wynagrodzenia pracowników',
        amount: 100000,
    },
    {
        key: '2',
        name: 'Zakup sprzętu komputerowego',
        amount: 50000,
    },
    {
        key: '3',
        name: 'Materiały biurowe',
        amount: 20000,
    },
    {
        key: '4',
        name: 'Opłaty za usługi telekomunikacyjne',
        amount: 15000,
    },
    {
        key: '5',
        name: 'Wynajem biura',
        amount: 80000,
    },
    {
        key: '6',
        name: 'Podróże służbowe',
        amount: 30000,
    },
];

const Budget = () => {
    return (
        <>
            <Title level={4}>Budżet jednostki organizacyjnej</Title>
            <Table columns={columns} dataSource={data} pagination={false} />
        </>
    );
};

export default Budget;