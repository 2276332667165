import React, {useRef, useState} from 'react';
import {Button, Card, Checkbox, message, Modal, Skeleton, Space, Tooltip, UploadFile, UploadProps} from "antd";
import {InfoCircleTwoTone, InboxOutlined} from "@ant-design/icons";
import Meta from "antd/es/card/Meta";
import Upload from "antd/lib/upload/Upload";
import './UserImportModal.css';
import Dragger from "antd/es/upload/Dragger";
import {BACKEND_BASE, GetToken} from "../../services/LoginService";
import {UserService} from "../../services/UserService";

export interface UserImportModalProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    refreshUsers: () => void;
}

function UserImportModal({open, setOpen, refreshUsers}: UserImportModalProps) {
    const [uploaded, setUploaded] = useState(false);
    const [filename, setFilename] = useState('');
    const [options, setOptions] = useState({overwrite: false, regeneratePasswords: false});
    const [showUploads, setShowUploads] = useState(false);

    const props: UploadProps = {
        name: 'file',
        multiple: false,
        accept: '.csv,.xlsx,.xls,text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel',
        maxCount: 1,
        action: `${BACKEND_BASE}/User/import`,
        headers: {
            'Authorization': 'Bearer ' + GetToken()
        },
        showUploadList: showUploads,
        onChange(info) {
            const {status} = info.file;
            setShowUploads(true);

            if (status === 'done') {
                message.success(`Plik ${info.file.name} został przesłany pomyślnie.`);
                setUploaded(true);
                setFilename(info.file.response.localName);
            } else if (status === 'error') {
                message.error(`Wystąpił błąd podczas przesyłania pliku ${info.file.name}. Upewnij się, że przesyłany plik jest zgodny z szablonem!`);
                setUploaded(false);
                setFilename('');
            }
        }
    };

    const triggerImport = () => {
        UserService.triggerImport(filename, options.overwrite, options.regeneratePasswords).then(async (x) => {
            if ("error" in x) {
                message.error(`Wystąpił błąd podczas importowania użytkowników. Upewnij się, że przesyłany plik jest zgodny z szablonem!`);
                return;
            }
            message.success('Import użytkowników zakończony pomyślnie.');
            setShowUploads(false);
            setUploaded(false);
            setFilename('');
            await refreshUsers();
            setOpen(false);
        });
    }

    return (
        <Modal
            title="Importowanie użytkowników"
            centered
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Anuluj
                </Button>,
                <Button key="submit" type="primary" disabled={!uploaded} onClick={triggerImport}>
                    Importuj
                </Button>,
            ]}
            onCancel={() => setOpen(false)}
            open={open}
            width={1000}
        >
            <Card className={'user-import-modal'}>
                <Skeleton avatar active loading={false}>
                    <Meta
                        avatar={<InfoCircleTwoTone style={{fontSize: '2rem'}}/>}
                        title="Dodawanie wielu użytkowników"
                        description={<div>
                            <p>W tej skecji możesz importować listę użytkowników.</p>
                            <p>Każdy użytkownik, dostanie losowo wygenerowane hasło na wskazany adres email. Użyj
                                poniższego szablonu Excel by dodać dane użytkowników. Adresy email muszą być unikalne.</p>
                            <a href={process.env.PUBLIC_URL + '/uzytkownicy_szablon.xlsx'} rel={'noreferrer'}
                               target={'_BLANK'}>Pobierz szablon Excel</a><br/>
                            <a href={process.env.PUBLIC_URL + '/uzytkownicy_szablon.csv'} rel={'noreferrer'}
                               target={'_BLANK'}>Pobierz szablon CSV</a>
                        </div>}
                    />
                </Skeleton>
            </Card>
            <div className={'user-import-modal__file-import'}>
                <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined/>
                    </p>
                    <p className="ant-upload-text">Naciśnij lub przeciągnij plik CSV/XLSX z użytkownikami</p>
                    <p className="ant-upload-hint">
                        Dodaj plik, a następnie rozpocznij proces importowania użytkowników. Jeśli dodasz kolejny plik,
                        poprzedni zostanie zastąpiony.
                    </p>
                </Dragger>
                <Space>
                    <Tooltip title={'Dane istniejących użytkowników zostaną nadpisane (jeżeli email jest już w bazie)'}>
                        <Checkbox onChange={(value) => setOptions({...options, overwrite: value.target.checked})}
                                  value={options.overwrite}>Nadpisz użytkowników</Checkbox>
                    </Tooltip>
                    <Tooltip title={'Wygeneruj nowe hasła dla istniejących użytkowników'}>
                        <Checkbox
                            onChange={(value) => setOptions({...options, regeneratePasswords: value.target.checked})}
                            value={options.regeneratePasswords}>Wygeneruj nowe hasła</Checkbox>
                    </Tooltip>
                </Space>
            </div>
        </Modal>
    );
}

export default UserImportModal;
