import React from 'react';
import './Login.css';
import {Checkbox, Input, Button, Form} from "antd";
import {useAuth} from "../../extensions/Auth";
import {MailOutlined, LockOutlined} from "@ant-design/icons";
import Logo from '../../assets/logo3.png'

function Login() {
    const auth = useAuth();
    const [loading, setLoading] = React.useState(false);
    const [loginError, setLoginError] = React.useState(false);

    const onFinish = (values: any) => {
        setLoading(true);
        setLoginError(false);
        const user = {
            email: values.email,
            password: values.password
        }

        auth.handleLogin(user).then((x: boolean) => {
            if(x)
                setLoginError(false);
            else
                setLoginError(true);

            setLoading(false);
        }).catch((e: any) => {
            setLoginError(true);
            setLoading(false);
        });
    };

    return (
        <div className="login">
            <div className="login__container">
                <div className={'login__form'}>
                    <div className={'login__form-wrapper'}>
                        <img src={Logo} className={'mobile-only-img'} />
                        <h1>Witaj!</h1>
                        <h3>Zaloguj się by uzyskać dostęp do intranetu!</h3>
                        <Form
                            name="basic"
                            layout={'vertical'}
                            className={'login__form-form'}
                            initialValues={{remember: true}}
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[{required: true, message: 'Adres email jest wymagany!'}]}
                            >
                                <Input prefix={<MailOutlined />} size={'large'} placeholder={'jan@domena.pl'}/>
                            </Form.Item>

                            <Form.Item
                                label="Hasło"
                                name="password"
                                rules={[{required: true, message: 'Hasło jest wymagane!'}]}
                            >
                                <Input.Password prefix={<LockOutlined />} size={'large'} placeholder={'Hasło...'}/>
                            </Form.Item>

                            <Form.Item name="remember" valuePropName="checked">
                                <Checkbox>Zapamiętaj mnie na 30 dni</Checkbox>
                            </Form.Item>

                            <Form.Item
                                validateStatus={loginError ? 'error' : 'success'}
                                help={loginError ? 'Podano nieprawidłowe dane logowania!' : ''}
                            >
                                <Button size={'large'} type="primary" htmlType="submit" style={{width: '100%'}} loading={loading}>
                                    Zaloguj
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
                <div className={'login__hero'}>
                    <img src={Logo} />
                </div>
            </div>
        </div>
    );
}

export default Login;
