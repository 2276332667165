import React from 'react';
import './InfoDepartment.css';
import {Tabs} from "antd";
import Modules from "../../modules/Modules";
import Budget from "../../../mockscreens/Budget";
import Safety from "../../../mockscreens/Safety";
import FirstAid from "../../../mockscreens/FirstAid";
import CashAssistance from "../../../mockscreens/CashAssistance";
import Gdpr from "../../../mockscreens/Gdpr";
import OrganisationalMatters from "../../../mockscreens/OrganisationalMatters";

const PhoneNumbers = () => <div>
    <Modules />
</div>

const items = [
    {label: "Budżet komórki organizacyjnej", key: 'deparment-1', children: <Budget />},
    {label: "BHP", key: 'deparment-2', children: <Safety />},
    {label: "Pierwsza pomoc", key: 'deparment-3', children: <FirstAid />},
    {label: "Kasa pożyczkowo-zapomogowa", key: 'deparment-4', children: <CashAssistance />},
    {label: "RODO", key: 'deparment-5', children: <Gdpr />},
    {label: "Sprawy organizacyjne", key: 'deparment-6', children: <OrganisationalMatters />},
]

function InfoDepartment() {
    return (
        <div className="infodepartment">
            <Tabs defaultActiveKey="1" tabBarStyle={{width: 300}} items={items} tabPosition={'left'} />
        </div>
    );
}

export default InfoDepartment;
