import { Table, Typography, Divider } from 'antd';

const { Title, Paragraph } = Typography;

const data = [
    {
        key: '1',
        name: 'Kasa Pożyczkowo-Zapomogowa',
        description: 'Kasa Pożyczkowo-Zapomogowa to instytucja udzielająca krótkoterminowych pożyczek oraz zapomóg dla osób poszkodowanych przez klęski żywiołowe, pożary, powodzie, wypadki komunikacyjne i inne wydarzenia losowe.',
        link: 'https://www.kasapozyczkowo-zapomogowa.gov.pl/'
    }
];

const columns = [
    {
        title: 'Nazwa',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Opis',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Link',
        dataIndex: 'link',
        key: 'link',
        render: (text: string) => <a href={text} target="_blank" rel="noopener noreferrer">{text}</a>,
    },
];

const CashAssistance = () => {
    return (
        <>
            <Title level={4}>Kasa Pożyczkowo-Zapomogowa</Title>
            <Paragraph>
                Kasa Pożyczkowo-Zapomogowa to instytucja udzielająca krótkoterminowych pożyczek oraz zapomóg dla osób poszkodowanych przez klęski żywiołowe, pożary, powodzie, wypadki komunikacyjne i inne wydarzenia losowe.
            </Paragraph>
            <Divider />
            <Table columns={columns} dataSource={data} pagination={false} />
        </>
    );
};

export default CashAssistance;