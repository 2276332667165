import React, {Key, useEffect, useState} from 'react';
import './System.css';
import {useAuth} from "../../extensions/Auth";
import {AdminScopes} from "../../types/Scopes";
import {
    Card,
    Skeleton,
    Radio,
    RadioChangeEvent,
    Button,
    Form,
    Input,
    message,
    Space,
    Tree,
    AutoComplete, Select, Tooltip, Popconfirm, Upload, UploadProps
} from "antd";
import Meta from "antd/es/card/Meta";
import {
    InfoCircleTwoTone,
    HomeOutlined,
    CloudOutlined,
    ApartmentOutlined,
    AppstoreOutlined,
    UploadOutlined,
    DownOutlined, CheckOutlined, CloseOutlined, DeleteOutlined
} from "@ant-design/icons";
import {SystemService} from "../../services/SystemService";
import {SiteSettings, SystemSettings} from "../../types/System";
import {UserApp} from "../../types/AuthState";
import {AppsMapper, CategoryMapper, SupportedApps} from "../../components/appsmodal/AppsModal";
import {RcFile} from "antd/es/upload";

const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

function System() {
    const auth = useAuth();
    const [authType, setAuthType] = useState(0);
    const [loading, setLoading] = useState(true);
    const [dataSource, setDataSource] = useState([] as any[]);
    const [selectedValues, setSelectedValues] = useState([] as Key[]);
    const [editMode, setEditMode] = useState({isEdit: false, value: {} as any, mode: 'category' as 'category' | 'app'});
    const [insertMode, setInsertMode] = useState({
        isInsert: false,
        value: {} as any,
        mode: 'category' as 'category' | 'app'
    });
    const [siteSettings, setSiteSettings] = useState({} as SiteSettings);
    const [form] = Form.useForm();
    const [siteSettingsForm] = Form.useForm();

    const onChange = (e: RadioChangeEvent) => {
        setAuthType(e.target.value);
    };

    const updateAuthMode = () => {
        SystemService.updateAuthMode(authType).then((x) => {
            if (x) message.success('Zmieniono tryb uwierzytelnienia.');
            else message.error('Wystąpił błąd podczas zmiany trybu uwierzytelnienia.');
        });
    }

    const updateLdapSettings = () => {
        form.validateFields().then((values) => {
            SystemService.updateLdapSettings(values).then((x) => {
                if (x) message.success('Zmieniono ustawienia serwera LDAP.');
                else message.error('Wystąpił błąd podczas zmiany ustawień serwera LDAP.');
            });
        });
    }

    const updateSiteSettings = () => {
        siteSettingsForm.validateFields().then((values) => {
            const formValues = {...siteSettings, title: values.title};
            console.log(formValues)

            SystemService.updateSiteSettings(formValues).then((x) => {
                if (x) message.success('Zmieniono ustawienia portaly.');
                else message.error('Wystąpił błąd podczas zmiany ustawień portalu.');
            });
        });
    }

    const onFormEdit = (event: any, key: string) => {
        let value = event?.target?.value ?? event;

        if (key === 'url') {
            const app = SupportedApps.find(x => x.value === value);

            if (app) value = app.key;
        }

        setEditMode({...editMode, value: {...editMode.value, [key]: value}});
    }

    const onInsertFormEdit = (event: any, key: string) => {
        let value = event?.target?.value ?? event;

        if (key === 'url') {
            const app = SupportedApps.find(x => x.value === value);

            if (app) value = app.key;
        }

        setInsertMode({...insertMode, value: {...insertMode.value, [key]: value}});
    }

    const onFormEditCategory = (event: any) => {
        setEditMode({...editMode, value: {...editMode.value, category: event.split('category-')[1]}});
    }

    const onInsertFormEditCategory = (event: any) => {
        setInsertMode({...insertMode, value: {...insertMode.value, category: event.split('category-')[1]}});
    }

    const loadApps = () => {
        SystemService.getDefaultUserApps().then((userApps: UserApp[]) => {
            const sortedApps = userApps.sort((a, b) => a.order !== undefined && b.order !== undefined ? a.order - b.order : 0);
            const categories = [] as any[];

            categories.push({
                title: 'Kategoria: Brak',
                key: `category-blank`,
                children: [] as any[]
            });

            const allCategories = sortedApps
                .filter((x) => x.isCategory)
                .map(x => CategoryMapper(x)).sort((a, b) => a.title.localeCompare(b.title));

            categories.push(...allCategories);

            sortedApps.forEach((x) => {
                const key = x.category ? `category-${x.category}` : 'category-blank';
                const category = categories.find(y => y.key === key);

                if (category && !x.isCategory) {
                    category.children.push(AppsMapper(x, key))
                }
            });

            setDataSource(categories);
        });
    }

    const deleteApp = (id: string) => {
        SystemService.deleteDefaultUserApp(id).then((x) => {
            if (x) {
                message.success(`${insertMode.mode === 'category' ? 'Kategoria' : 'Aplikacja'} została usunięta!`);
                loadApps();
                cancelEdit();
            } else {
                message.error(`Wystąpił błąd podczas usuwania ${insertMode.mode === 'category' ? 'kategorii' : 'aplikacji'}!`);
            }
        });
    }

    const saveEdit = () => {
        const payload: UserApp = {
            id: editMode.value.id,
            displayName: editMode.value.displayName,
            order: editMode.value.order,
            isCategory: editMode.value.isCategory
        }

        if (!editMode.value.isCategory) {
            payload.category = editMode.value.category;
            payload.url = editMode.value.url;
        }

        SystemService.editDefaultUserApp(payload).then((x) => {
            if (x) {
                message.success(`${insertMode.value.isCategory ? 'Kategoria' : 'Aplikacja'} została zapisana`);
                cancelEdit();
                loadApps();
            } else {
                message.error(`Wystąpił błąd podczas zapisywania ${insertMode.value.isCategory ? 'kategorii' : 'aplikacji'}`);
            }
        });
    }

    const saveInsert = () => {
        const payload: UserApp = {
            id: insertMode.value.id,
            displayName: insertMode.value.displayName,
            order: insertMode.value.order,
            isCategory: insertMode.mode === 'category'
        }

        if (!insertMode.value.isCategory) {
            payload.category = insertMode.value.category;
            payload.url = insertMode.value.url;
        }

        SystemService.addDefaultUserApp(payload).then((x) => {
            if (x) {
                message.success(`${insertMode.mode === 'category' ? 'Kategoria' : 'Aplikacja'} została dodana!`);
                loadApps();
                cancelInsertMode();
            } else {
                message.error(`Wystąpił błąd podczas dodawania ${insertMode.mode === 'category' ? 'kategorii' : 'aplikacji'}${insertMode.mode === 'category' ? ', kategoria już istnieje!' : ''}!`);
            }
        });
    }

    const cancelEdit = () => {
        setEditMode({isEdit: false, value: {}, mode: 'category'})
        setSelectedValues(['']);
    }

    const cancelInsertMode = () => {
        setInsertMode({isInsert: false, value: {}, mode: 'category'})
    }

    const editApp = (key: Key[]) => {
        cancelInsertMode();
        if (key.length > 0 && key[0] !== 'category-blank') {
            let app = dataSource.find(x => x.key === key[0]);

            if (app) {
                app.isEdit = true;
                setEditMode({isEdit: true, value: app, mode: 'category'});
            } else {
                dataSource.forEach(x => {
                    if (x.children) {
                        app = x.children.find((y: any) => y.key === key[0]);

                        if (app) {
                            setEditMode({isEdit: true, value: app, mode: 'app'});
                            app.isEdit = true;
                        }
                    }
                })
            }
        } else {
            const data = [...dataSource];

            data.forEach(x => {
                x.isEdit = false;

                if (x.children) {
                    x.children.forEach((y: any) => {
                        y.isEdit = false;
                    });
                }
            })

            setEditMode({isEdit: false, value: {}, mode: 'category'});
        }

        setSelectedValues(key);
    }

    const logoUploadProps: UploadProps = {
        maxCount: 1,
        customRequest: (options) => null,
        beforeUpload: (file) => {
            const isValid = file.type.startsWith('image/');

            if (!isValid) {
                message.error(`Logo musi być plikiem graficznym!`);
            }

            return isValid || Upload.LIST_IGNORE;
        },
        onChange: async (info) => {
            info.fileList[0].status = 'done';
            getBase64(info.fileList[0].originFileObj!).then((r) => {
                setSiteSettings({...siteSettings, logo: r});
            });
        },
    };

    const ActionButtons = () => <Space>
        <Tooltip title={'Zapisz zmiany'}>
            <CheckOutlined onClick={() => editMode.isEdit ? saveEdit() : saveInsert()}/>
        </Tooltip>
        <Tooltip title={'Odrzuć zmiany'}>
            <CloseOutlined onClick={() => {
                cancelEdit()
                cancelInsertMode()
            }
            }/>
        </Tooltip>
        {editMode.isEdit && <Tooltip title={`Usuń ${editMode.mode === 'category' ? 'kategorię' : 'aplikację'}`}>
            <Popconfirm
                placement="top"
                title={`Czy jesteś pewien?`}
                description={`Czy na pewno chcesz usunąć ${editMode.mode === 'category' ? 'kategorię' : 'aplikację'} ${editMode.value.displayName}?`}
                onConfirm={() => deleteApp(editMode.value.id)}
                okText="Tak"
                cancelText="Nie"
            >
                <DeleteOutlined/>
            </Popconfirm>
        </Tooltip>}
    </Space>

    useEffect(() => {
        auth.verifyAuth(AdminScopes.USERS);
        loadApps();

        SystemService.getSiteSettings().then((res: SiteSettings | null) => {
            if(res) {
                siteSettingsForm.setFieldsValue({
                    title: res.title
                });
                setSiteSettings(res);
            }
        });

        SystemService.getSystemSettings().then((res: SystemSettings) => {
            setAuthType(res.authenticationMode);
            form.setFieldsValue({
                hostname: res.ldapServer.hostname,
                port: res.ldapServer.port,
                baseDn: res.ldapServer.baseDn,
                userDn: res.ldapServer.userDn,
                ldapVersion: res.ldapServer.ldapVersion,
            });
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
        });
    }, []);

    return (
        <div className="system">
            <Card>
                <Skeleton avatar active loading={false}>
                    <Meta
                        avatar={<InfoCircleTwoTone style={{fontSize: '2rem'}}/>}
                        title="Ustawienia systemowe"
                        description={<div>
                            <p>W tej skecji możesz zmienić ustawienia globalne systemu.</p>
                            <p>Ustawienia, które mogą zostać zmienione to między innymi sposób uwierzytelnienia lub
                                serwer uwierzytelniający.</p>
                        </div>}
                    />
                </Skeleton>
            </Card>
            <Card className={'system__site-settings'}>
                <h3>Ustawienia portalu</h3>
                {!loading && <Form
                    name="site-settings"
                    layout={'vertical'}
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    style={{maxWidth: 600}}
                    form={siteSettingsForm}
                    onFinish={updateSiteSettings}
                    initialValues={{remember: true}}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Nazwa portalu"
                        name="title"
                        rules={[{required: true, message: 'Nazwa portalu jest wymagana!'}]}
                    >
                        <Input placeholder={'Intranet by Nabli'}/>
                    </Form.Item>

                    <Form.Item
                        label="Logo"
                        name="logo"
                    >
                        <Upload {...logoUploadProps}>
                            <Button icon={<UploadOutlined/>}>Dodaj obraz</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Zapisz
                        </Button>
                    </Form.Item>
                </Form>}
                {loading && <Skeleton active/>}
            </Card>
            <Card className={'system__auth-mode'}>
                <h3 className={'system__headings'}>Tryb uwierzytelnienia</h3>
                {authType === 0 && <Card>
                    <Skeleton avatar active loading={false}>
                        <Meta
                            avatar={<HomeOutlined style={{fontSize: '1rem'}}/>}
                            title="Uwierzytelnienie lokalne"
                            description={<div>
                                <p>Użytkownicy będą przechowywani w bazie danych intranetu. Uwierzytelnienie będzie
                                    przeprowadzone korzystając z tej bazy danych.</p>
                            </div>}
                        />
                    </Skeleton>
                </Card>}
                {authType === 1 && <Card>
                    <Skeleton avatar active loading={false}>
                        <Meta
                            avatar={<CloudOutlined style={{fontSize: '1rem'}}/>}
                            title="Uwierzytelnienie LDAP / ActiveDirectory"
                            description={<div>
                                <p>Użytkownicy będą przechowywani w bazie danych intranetu. Uwierzytelnienie będzie
                                    przeprowadzone poprzez łączenie się za pomocą protokołu LDAP z ActiveDirectory.</p>
                            </div>}
                        />
                    </Skeleton>
                </Card>}
                {authType === 2 && <Card>
                    <Skeleton avatar active loading={false}>
                        <Meta
                            avatar={<ApartmentOutlined style={{fontSize: '1rem'}}/>}
                            title="Uwierzytelnienie hybrydowe"
                            description={<div>
                                <p>Użytkownicy będą przechowywani w bazie danych intranetu. Uwierzytelnienie będzie
                                    przeprowadzone poprzez łączenie się za pomocą protokołu LDAP z ActiveDirectory,
                                    jeśli użytkownik nie istnieje lub podane hasło jest nieprawidłowe, uwierzytelnienie
                                    będzie przeprowadzone na podstawie bazy danych.</p>
                            </div>}
                        />
                    </Skeleton>
                </Card>}
                {!loading && <div className={'system__auth-mode__actions'}>
                    <Radio.Group onChange={onChange} value={authType}>
                        <Radio value={0}>Lokalny</Radio>
                        <Radio value={1}>LDAP / ActiveDirectory</Radio>
                        <Radio value={2}>Hybrydowy</Radio>
                    </Radio.Group>
                    <Button type={'primary'} onClick={updateAuthMode}>Zapisz</Button>
                </div>}
                {loading && <Skeleton active/>}
            </Card>
            <Card className={'system__ldap-configuration'}>
                <h3>Konfiguracja połączenia LDAP</h3>
                {!loading && <Form
                    name="basic"
                    layout={'vertical'}
                    disabled={authType === 0}
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    style={{maxWidth: 600}}
                    form={form}
                    onFinish={updateLdapSettings}
                    initialValues={{remember: true}}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Adres serwera"
                        name="hostname"
                        rules={[{required: true, message: 'Adres serwera jest wymagany!'}]}
                    >
                        <Input placeholder={'127.0.0.1'}/>
                    </Form.Item>

                    <Form.Item
                        label="Port"
                        name="port"
                        rules={[{required: true, message: 'Port jest wymagany!'}]}
                    >
                        <Input type={'number'} placeholder={'389'}/>
                    </Form.Item>

                    <Form.Item
                        label="Podstawowe DN (Base DN)"
                        name="baseDn"
                        rules={[{required: true, message: 'Podstawowe DN jest wymagane!'}]}
                    >
                        <Input placeholder={'dc=example,dc=org'}/>
                    </Form.Item>

                    <Form.Item
                        label="DN Użytkowników (User DN)"
                        name="userDn"
                        rules={[{required: true, message: 'DN Użytkowników jest wymagane!'}]}
                    >
                        <Input placeholder={'ou=users'}/>
                    </Form.Item>

                    <Form.Item label="Wersja protokołu LDAP" name={'ldapVersion'}>
                        <Radio.Group>
                            <Radio value={2}> LDAP v2 </Radio>
                            <Radio value={3}> LDAP v3 </Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Zapisz
                        </Button>
                    </Form.Item>
                </Form>}
                {loading && <Skeleton active/>}
            </Card>
            <Card className={'system__default-apps'}>
                <h3 className={'system__headings'}>Domyślne aplikacje użytkowników</h3>
                <Card>
                    <Skeleton avatar active loading={false}>
                        <Meta
                            avatar={<AppstoreOutlined style={{fontSize: '1rem'}}/>}
                            title="Aplikacje"
                            description={<div>
                                <p>W tej sekcji możesz skonfigurować, które aplikacje będą domyślnie wyświetlane
                                    użytkownikom w sekcji "Twoje aplikacje".</p>
                            </div>}
                        />
                    </Skeleton>
                </Card>
                <Space>
                    <Button onClick={() => {
                        setInsertMode({isInsert: true, value: {}, mode: 'app'})
                        cancelEdit();
                    }}>
                        Dodaj aplikację
                    </Button>
                    <Button onClick={() => {
                        setInsertMode({isInsert: true, value: {}, mode: 'category'})
                        cancelEdit();
                    }}>
                        Dodaj kategorię
                    </Button>
                </Space>
                <Tree
                    showLine
                    expandedKeys={dataSource.map(item => item.key)}
                    switcherIcon={<DownOutlined/>}
                    defaultExpandedKeys={selectedValues}
                    selectedKeys={selectedValues}
                    treeData={dataSource}
                    onSelect={(selectedKeys, info) => editApp(selectedKeys)}
                />
                {editMode.isEdit &&
                    <Card title={<div className={'apps-modal__edit-form-header'}>
                        <span>Edytowanie {editMode.mode === 'category' ? 'kategorii' : 'aplikacji'}</span>
                        <ActionButtons/></div>}>
                        {editMode.mode === 'category' && <div>
                            <div className={'apps-modal__edit-form'}>
                                <div>
                                    <label>Nazwa</label>
                                    <Input value={editMode.value.displayName}
                                           onChange={(x) => onFormEdit(x, 'displayName')}/>
                                </div>
                            </div>
                        </div>}
                        {editMode.mode === 'app' && <div>
                            <div className={'apps-modal__edit-form'}>
                                <div>
                                    <label>Nazwa</label>
                                    <Input value={editMode.value.displayName}
                                           onChange={(x) => onFormEdit(x, 'displayName')}/>
                                </div>
                                <div>
                                    <label>Link</label>
                                    <AutoComplete
                                        options={SupportedApps}
                                        defaultValue={editMode.value.url}
                                        onSearch={(x) => onFormEdit(x, 'url')}
                                        onSelect={(x) => onFormEdit(x, 'url')}
                                    />
                                </div>
                                <div>
                                    <label>Kategoria</label>
                                    <Select defaultValue={editMode.value.category} style={{width: '100%'}} allowClear
                                            onChange={(x) => onFormEditCategory(x)}>
                                        <Select.Option value={''}>Brak</Select.Option>
                                        {dataSource.filter(x => x.key !== 'category-blank').map(x => <Select.Option
                                            value={x.key} key={x.key}>{x.title}</Select.Option>)}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        }
                    </Card>
                }
                {insertMode.isInsert &&
                    <Card title={<div className={'apps-modal__edit-form-header'}>
                        <span>Dodawanie {insertMode.mode === 'category' ? 'kategorii' : 'aplikacji'}</span>
                        <ActionButtons/></div>}>
                        {insertMode.mode === 'category' && <div>
                            <div className={'apps-modal__edit-form'}>
                                <div>
                                    <label>Nazwa</label>
                                    <Input value={insertMode.value.displayName}
                                           onChange={(x) => onInsertFormEdit(x, 'displayName')}/>
                                </div>
                            </div>
                        </div>}
                        {insertMode.mode === 'app' && <div>
                            <div className={'apps-modal__edit-form'}>
                                <div>
                                    <label>Nazwa</label>
                                    <Input value={insertMode.value.displayName}
                                           onChange={(x) => onInsertFormEdit(x, 'displayName')}/>
                                </div>
                                <div>
                                    <label>Link</label>
                                    <AutoComplete
                                        options={SupportedApps}
                                        onSearch={(x) => onInsertFormEdit(x, 'url')}
                                        onSelect={(x) => onInsertFormEdit(x, 'url')}
                                    />
                                </div>
                                <div>
                                    <label>Kategoria</label>
                                    <Select defaultValue={insertMode.value.category} style={{width: '100%'}} allowClear
                                            onChange={(x) => onInsertFormEditCategory(x)}>
                                        <Select.Option value={''}>Brak</Select.Option>
                                        {dataSource.filter(x => x.key !== 'category-blank').map(x => <Select.Option
                                            value={x.key} key={x.key}>{x.title}</Select.Option>)}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        }
                    </Card>
                }
            </Card>
        </div>
    );
}

export default System;
