import React, {useState, useContext, createContext} from "react";
import AuthState, {AuthUser, User} from "../types/AuthState";
import Router from "./Routing";
import {LoginService} from "../services/LoginService";
import {NavMap} from "./Menu";
import {AdminScopes} from "../types/Scopes";

export function ProvideAuth({children}: any) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

const authContext = createContext({} as AuthState);

export const useAuth = () => {
    return useContext(authContext);
};

export interface Unauthorized {
    type: 'unauthorized';
}

function useProvideAuth() {
    const authValue = localStorage.getItem('auth');
    const [user, setUser] = useState<User>(authValue ? JSON.parse(authValue) : {} as User);
    const [route, setRoute] = useState(window.location.pathname);

    const handleLogin = async (user: AuthUser): Promise<boolean> => {
        const loginResult = await LoginService.login(user.email, user.password);

        if(loginResult) {
            localStorage.setItem('auth', JSON.stringify(loginResult));
            setUser(loginResult);
            return true;
        }

        return false;
    };

    const navigate = (path: string, external = false) => {
        if(external) {
            window.open(path, '_blank');
            return;
        }

        Router.navigate(path).then(x => setRoute(path));
        document.title = 'Intranet by Nabli - ' + (!user?.isAuthenticated ? 'Zaloguj się' : NavMap[path]);
    }

    const handleLogout = () => {
        localStorage.removeItem('auth');
        setUser({} as User);
    };

    const verifyAuth = (scope: number) => {
        if(!user?.scopes?.includes(scope)) {
            console.log(scope)
            console.log(user?.scopes)
            navigate('/');
        }
    }

    return {
        user,
        route,
        handleLogin,
        handleLogout,
        navigate,
        verifyAuth
    };
}
