import React, {useEffect, useState} from 'react';
import './News.css';
import {Button, Menu, MenuProps, Pagination, Spin} from "antd";
import NewsCard, {NewsCardProps} from "../../components/newscard/NewsCard";
import {NewsService} from "../../services/NewsService";
import {useAuth} from "../../extensions/Auth";

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

function News() {
    const auth = useAuth();
    const [page, setPage] = useState(1);
    const [data, setData] = useState([] as NewsCardProps[]);
    const [newsData, setNewsData] = useState([] as NewsCardProps[]);
    const [loading, setLoading] = useState(true);

    const filter = (p = 1, input = data) => setNewsData(input.filter((_, index) => index >= (p - 1) * 10 && index < p * 10));

    const items: MenuProps['items'] = [
        getItem('Szybki dostęp', 'grp-1', null, [
            getItem('Numery wewnętrzne', 'grp-1-1'),
            getItem('Polityka prywatności', 'grp-1-2'),
            getItem('Kadry', 'grp-1-3'),

        ], 'group'),
        getItem('Międzywydziałowe', 'grp-2', null, [
            getItem('BHP', 'grp-2-1'),
            getItem('Pierwsza pomoc', 'grp-2-2'),
            getItem('RODO', 'grp-2-3'),
        ], 'group'),
        getItem('Urzędowe', 'grp-3', null, [
            getItem('Regulamin pracy', 'grp-3-1')
        ], 'group'),
    ];

    useEffect(() => {
        setLoading(true);
        NewsService.getNews().then((r) => {
            setData(r);
            filter(page, r);
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
            auth.handleLogout();
        });
    }, []);

    const updatePagination = (page: number) => {
        setPage(page);
        filter(page);
    }

    return (
        <div className="news">
            <div className={'news__wrapper'}>
                <div className={'news__links'}>
                    <Menu
                        style={{width: 256}}
                        defaultSelectedKeys={['1']}
                        defaultOpenKeys={['sub1']}
                        mode="inline"
                        items={items}
                    />
                </div>
                <div className={'news__container'}>
                    <div className={'news__new'}>
                        <Button type={'primary'} ghost onClick={() => auth.navigate('/news/new')}>Dodaj aktualność</Button>
                    </div>
                    <div className="news__content">
                        {!loading && newsData.map((news, index) => <NewsCard key={index} {...news} />)}
                        {loading && <Spin tip="Ładowanie..." size="large" className={'loader'}/>}
                    </div>
                    <Pagination defaultCurrent={1} total={data.length} onChange={updatePagination}
                                showSizeChanger={false}/>
                </div>
            </div>
        </div>
    );
}

export default News;
