import {MenuProps} from "antd";
import React from "react";

type MenuItem = Required<MenuProps>['items'][number];

export default function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
    onClick?: (e: React.MouseEvent) => void
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
        onClick
    } as MenuItem;
}

export const NavMap: any = {
    '/': 'Aktualności',
    '/news/new' : 'Dodaj aktualność',
    '/news/manage': 'Redagowanie aktualności',
    '/software': 'Oprogramowanie',
    '/software/new': 'Dodaj oprogramowanie',
    '/delegations': 'Delegacje',
    '/aiassistant': 'Asystent AI',
    '/holiday': 'Urlop',
    '/mail': 'Poczta',
    '/modules': 'Moduły',
    '/info': 'Informacje',
    '/profile': 'Profil',
    '/training': 'Poradniki',
    '/training/new': 'Dodaj poradnik',
    '/users': 'Użytkownicy',
    '/users/new': 'Użytkownicy - Dodaj',
    '/settings': 'Ustawienia systemu',
}
