import { Row, Col, Card, Typography } from 'antd';

const { Title } = Typography;

const departments = [
    {
        name: 'Biuro Obsługi Interesantów',
        subcategories: [
            {
                name: 'Informacja ogólna',
                numbers: ['101', '102'],
            },
            {
                name: 'Wydawanie dowodów osobistych',
                numbers: ['103'],
            },
            {
                name: 'Sprawy mieszkańców',
                numbers: ['104'],
            },
        ],
    },
    {
        name: 'Dział Architektury i Urbanistyki',
        subcategories: [
            {
                name: 'Planowanie przestrzenne',
                numbers: ['201', '202'],
            },
            {
                name: 'Wykup nieruchomości',
                numbers: ['203'],
            },
            {
                name: 'Zgłaszanie awarii',
                numbers: ['204'],
            },
        ],
    },
    {
        name: 'Wydział Gospodarki Komunalnej',
        subcategories: [
            {
                name: 'Gospodarka odpadami',
                numbers: ['301'],
            },
            {
                name: 'Drogownictwo',
                numbers: ['302'],
            },
            {
                name: 'Zarządzanie nieruchomościami',
                numbers: ['303'],
            },
        ],
    },
    {
        name: 'Dział Inwestycji i Funduszy Europejskich',
        subcategories: [
            {
                name: 'Fundusze europejskie',
                numbers: ['401'],
            },
            {
                name: 'Inwestycje miejskie',
                numbers: ['402'],
            },
        ],
    },
    {
        name: 'Referat Ochrony Środowiska',
        subcategories: [
            {
                name: 'Ochrona powietrza',
                numbers: ['501'],
            },
            {
                name: 'Ochrona wód',
                numbers: ['502'],
            },
        ],
    },
];

const PhoneNumbers = () => {
    return (
        <div>
            <Title level={4}>Numery wewnętrzne działów</Title>
            {departments.map((dept) => (
                <div key={dept.name}>
                    <Title level={5}>{dept.name}</Title>
                    <Row gutter={[16, 16]}>
                        {dept.subcategories.map((subcat) => (
                            <Col span={8} key={subcat.name}>
                                <Card title={subcat.name} bordered>
                                    {subcat.numbers.map((num) => (
                                        <p key={num}>Tel: {num}</p>
                                    ))}
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            ))}
        </div>
    );
};

export default PhoneNumbers;