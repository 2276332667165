import React, {useRef} from 'react';
import './AiAssistant.css';
import {Button, Card, Input, Layout, List, Space, Spin} from "antd";
import Loader from "./components/Loader";

const {Header, Footer, Sider, Content} = Layout;

const MOCK_MESSAGES = [
    {
        message: 'Wyobraź sobie, że jesteś pracownikiem instytucji publicznej, musisz napisać raport na temat stanu ławek w Parku Miejskim przy ulicy Kwiatowej. Powiedz z jakiego programu bedziesz korzystał do napisania raportu',
        type: 'user'
    },
    {
        message: '<div class="markdown prose w-full break-words dark:prose-invert light"><p>Jeśli jestem pracownikiem instytucji publicznej i mam napisać raport na temat stanu ławek w Parku Miejskim przy ulicy Kwiatowej, to prawdopodobnie użyję programu do przetwarzania tekstu, który jest powszechnie stosowany w mojej organizacji. Oto kilka popularnych opcji:</p><ol><li><p>Microsoft Word: Jest to popularny program do przetwarzania tekstu, który oferuje wiele narzędzi do tworzenia profesjonalnych raportów. Można w nim formatować tekst, dodawać obrazy, tabela, i wiele innych elementów.</p></li><li><p>Google Docs: To bezpłatna platforma do tworzenia i edytowania dokumentów online. Umożliwia współpracę z innymi użytkownikami w czasie rzeczywistym, co może być przydatne, jeśli inni pracownicy również będą pracować nad raportem.</p></li><li><p>LibreOffice Writer: Jest to bezpłatna alternatywa dla Microsoft Word, która oferuje podobne funkcje i narzędzia do tworzenia dokumentów.</p></li><li><p>Notatnik lub innego prostego edytora tekstu: Jeśli raport jest stosunkowo prosty, można użyć podstawowego edytora tekstu, takiego jak Notatnik na systemie Windows lub TextEdit na systemie macOS.</p></li></ol><p>Wybór programu zależy od preferencji organizacji, dostępności oprogramowania oraz konkretnych potrzeb przy tworzeniu raportu. Głównym celem jest stworzenie czytelnego, zrozumiałego i profesjonalnego dokumentu, który będzie zawierał informacje na temat stanu ławek w Parku Miejskim przy ulicy Kwiatowej.</p></div>',
        type: 'bot',
        mode: 'HTML'
    },
    {message: 'Teraz napisz 4 punktowy konspekt takiego planu', type: 'user'},
    {
        message: '<div className="markdown prose w-full break-words dark:prose-invert light"><p>Oto 4-punktowy konspekt\n' +
            '            planu dotyczącego raportu na temat stanu ławek w Parku Miejskim przy ulicy Kwiatowej:</p>\n' +
            '            <ol>\n' +
            '                <li><p><strong>Wprowadzenie</strong></p>\n' +
            '                    <ul>\n' +
            '                        <li>Krótka prezentacja parku i jego znaczenia dla społeczności lokalnej.</li>\n' +
            '                        <li>Cel raportu: Dokładna ocena stanu technicznego i estetycznego ławek w parku.</li>\n' +
            '                        <li>Zakres raportu: Określenie, ile ławek jest w parku, ich lokalizacje oraz ogólny stan.</li>\n' +
            '                    </ul>\n' +
            '                </li>\n' +
            '                <li><p><strong>Metodologia i Zbieranie Danych</strong></p>\n' +
            '                    <ul>\n' +
            '                        <li>Opis metodyki oceny stanu ławek, w tym rodzaje przeprowadzanych inspekcji (np. techniczne,\n' +
            '                            estetyczne).\n' +
            '                        </li>\n' +
            '                        <li>Wyjaśnienie, kto przeprowadzał inspekcje i w jaki sposób zbierano dane.</li>\n' +
            '                        <li>Określenie okresu, w którym przeprowadzono ocenę stanu ławek.</li>\n' +
            '                    </ul>\n' +
            '                </li>\n' +
            '                <li><p><strong>Wyniki i Analiza</strong></p>\n' +
            '                    <ul>\n' +
            '                        <li>Prezentacja wyników oceny stanu ławek, w tym:\n' +
            '                            <ul>\n' +
            '                                <li>Liczba ławek w dobrym stanie,</li>\n' +
            '                                <li>Liczba ławek wymagających naprawy lub renowacji,</li>\n' +
            '                                <li>Opis konkretnych problemów technicznych i estetycznych.</li>\n' +
            '                            </ul>\n' +
            '                        </li>\n' +
            '                        <li>Analiza uzyskanych danych, identyfikacja głównych problemów oraz ich ewentualny wpływ na\n' +
            '                            użytkowników parku.\n' +
            '                        </li>\n' +
            '                    </ul>\n' +
            '                </li>\n' +
            '                <li><p><strong>Rekomendacje i Działania Naprawcze</strong></p>\n' +
            '                    <ul>\n' +
            '                        <li>Propozycje działań naprawczych, które należy podjąć w związku z wynikami oceny.</li>\n' +
            '                        <li>Określenie priorytetów napraw i renowacji oraz oszacowanie kosztów.</li>\n' +
            '                        <li>Sugestie dotyczące terminów i harmonogramu działań naprawczych.</li>\n' +
            '                        <li>Podkreślenie znaczenia utrzymania ławek w dobrym stanie dla społeczności lokalnej i\n' +
            '                            użytkowników parku.\n' +
            '                        </li>\n' +
            '                    </ul>\n' +
            '                </li>\n' +
            '            </ol>\n' +
            '            <p>Ten konspekt stanowi ogólną strukturę raportu i pomoże w zorganizowaniu informacji oraz przedstawieniu\n' +
            '                wyników oceny ławek w Parku Miejskim przy ulicy Kwiatowej w klarowny i systematyczny sposób.</p></div>',
        type: 'bot',
        mode: 'HTML'
    },
]

const data = [
    {
        id: 'a1b2c3d4-e5f6-g7h8-i9j0-k1l2m3n4o5p6',
        title: 'Jak korzystać z Google?',
    },
    {
        id: 'a1b2c3d4-e5f6-g7h8-i9j0-k1l2m3n4o5p7',
        title: 'Jak pozyskać dotację?'
    },
    {
        id: 'a1b2c3d4-e5f6-g7h8-i9j0-k1l2m3n4o5p8',
        title: 'Jak utworzyć raport na temat podatku VAT?'
    }
];

const getMessages = (id: string) => {
    switch (id) {
        case 'a1b2c3d4-e5f6-g7h8-i9j0-k1l2m3n4o5p6':
            return [
                {message: 'Jak korzystać z Google?', type: 'user'},
                {
                    message: 'Google to popularna wyszukiwarka internetowa, która umożliwia wyszukiwanie informacji na różne tematy. Aby skorzystać z Google, wykonaj następujące kroki:\n' +
                        '\n' +
                        'Wejdź na stronę www.google.com lub wpisz "google" w wyszukiwarkę internetową.\n' +
                        '\n' +
                        'W polu wyszukiwania wpisz słowa kluczowe, czyli frazy, na temat których chcesz znaleźć informacje. Na przykład, jeśli szukasz przepisu na ciasto czekoladowe, wpisz "przepis na ciasto czekoladowe".\n' +
                        '\n' +
                        'Kliknij przycisk "Szukaj" lub naciśnij klawisz Enter na klawiaturze.\n' +
                        '\n' +
                        'Google wyświetli listę stron internetowych, które zawierają informacje na temat wyszukiwanej frazy. Kliknij na tytuł strony, aby otworzyć ją w nowym oknie.\n' +
                        '\n' +
                        'Jeśli chcesz zawęzić wyniki wyszukiwania, możesz skorzystać z różnych narzędzi, takich jak filtry, kategorie, zakładki i inne.\n' +
                        '\n' +
                        'Po znalezieniu poszukiwanych informacji możesz zapisać stronę do zakładek, skopiować link i wysłać go do kogoś, kto szuka podobnych informacji, lub po prostu zamknąć okno przeglądarki.\n' +
                        '\n' +
                        'Ważne jest, aby pamiętać, że wyniki wyszukiwania mogą być różne w zależności od słów kluczowych, które wpiszesz, a także od algorytmów Google. Dlatego warto eksperymentować z różnymi frazami i filtrami, aby znaleźć najlepsze informacje na temat wyszukiwanego tematu.',
                    type: 'bot'
                },
            ];
        case 'a1b2c3d4-e5f6-g7h8-i9j0-k1l2m3n4o5p7':
            return [
                {message: 'Jak pozyskać dotację?', type: 'user'},
                {
                    message: 'Pozyskanie dotacji może być skomplikowanym procesem, ale ogólnie rzecz biorąc, wymaga zwykle wykonania następujących kroków:\n' +
                        '\n' +
                        'Znalezienie źródła finansowania: Możesz szukać dotacji w różnych źródłach, takich jak fundacje, organizacje non-profit, instytucje rządowe, a także w prywatnych firmach, które oferują programy dotacyjne. Najlepiej jest poszukać w internecie, ponieważ wiele organizacji ma swoje strony internetowe z informacjami na temat dotacji.\n' +
                        '\n' +
                        'Wybór odpowiedniego programu dotacyjnego: Po znalezieniu źródła finansowania, należy dokładnie przejrzeć informacje o programie dotacyjnym i upewnić się, że odpowiada on Twoim potrzebom. Warto zwrócić uwagę na wymagania dotyczące projektu, terminy składania wniosków, kwoty finansowania i warunki udzielania dotacji.\n' +
                        '\n' +
                        'Przygotowanie wniosku: Aby ubiegać się o dotację, musisz zwykle złożyć wniosek, który powinien zawierać informacje o projekcie lub działalności, dla której potrzebujesz dotacji, jak również informacje o sobie lub Twojej organizacji. Wnioskodawcy często wymagają szczegółowego opisu celu projektu, harmonogramu, budżetu oraz wyjaśnienia, jak projekt przyczyni się do rozwoju społeczności lub wpłynie na poprawę jakości życia.\n' +
                        '\n' +
                        'Składanie wniosków: W przypadku większości programów dotacyjnych, wnioski należy składać online lub za pośrednictwem poczty tradycyjnej. Upewnij się, że termin składania wniosków nie minął i że wszystkie wymagane dokumenty zostały dołączone do wniosku.\n' +
                        '\n' +
                        'Ocena i wyniki: Po złożeniu wniosku, organizacja lub fundacja, która prowadzi program dotacyjny, przejdzie do oceny wniosków i ogłosi wyniki. W przypadku przyznania dotacji, musisz przestrzegać zasad i warunków finansowania, a także regularnie raportować o postępach projektu.\n' +
                        '\n' +
                        'Warto pamiętać, że proces pozyskiwania dotacji może być skomplikowany i wymagać znacznych nakładów czasu i wysiłku. Jednak jeśli Twój projekt jest dobrze przemyślany i odpowiada na potrzeby społeczne lub działań na rzecz dobra publicznego, to szansa na przyznanie dotacji jest większa.',
                    type: 'bot'
                }
            ];
        case 'a1b2c3d4-e5f6-g7h8-i9j0-k1l2m3n4o5p8':
            return [
                {message: 'Jak utworzyć raport na temat podatku VAT?', type: 'user'},
                {
                    message: 'Aby utworzyć raport na temat podatku VAT, należy wykonać następujące kroki:\n' +
                        '\n' +
                        'Zbierz wszystkie dokumenty dotyczące sprzedaży i zakupów: Aby utworzyć raport VAT, musisz mieć dostęp do dokumentów dotyczących transakcji, takich jak faktury sprzedaży i zakupu, protokoły, umowy oraz inne dokumenty, które potwierdzają zawarcie umowy.\n' +
                        '\n' +
                        'Sprawdź, czy wszystkie dokumenty są kompletnie i poprawnie wypełnione: Upewnij się, że wszystkie dokumenty są poprawnie wypełnione i zawierają wymagane informacje, takie jak numer NIP, daty, wartości sprzedaży, kwoty podatku VAT itp. Jeśli zauważysz jakieś błędy, należy je poprawić.\n' +
                        '\n' +
                        'Przyporządkuj transakcje do odpowiednich kategorii VAT: Każda transakcja musi być przypisana do właściwej kategorii VAT. W Polsce, są to trzy kategorie: stawka 23%, stawka 8% oraz stawka 5%. Niektóre transakcje, takie jak eksport, są zwolnione z podatku VAT.\n' +
                        '\n' +
                        'Oblicz kwotę podatku VAT: Po przyporządkowaniu transakcji do odpowiednich kategorii VAT, oblicz kwotę podatku VAT, która wynika z tych transakcji. Kwoty podatku VAT należy wyodrębnić na poszczególne stawki.\n' +
                        '\n' +
                        'Utwórz raport VAT: Na podstawie zebranych dokumentów i obliczeń, utwórz raport VAT, który zawiera wszystkie niezbędne informacje, takie jak kwoty VAT w poszczególnych stawkach, wartość netto i brutto, sumy kwot VAT itp.\n' +
                        '\n' +
                        'Złóż deklarację VAT: Ostatecznie, na podstawie raportu VAT, złóż deklarację VAT do odpowiedniego organu podatkowego. Upewnij się, że termin składania deklaracji VAT został przestrzegany.\n' +
                        '\n' +
                        'Warto pamiętać, że tworzenie raportu VAT może być skomplikowane i czasochłonne, szczególnie jeśli jest to pierwszy raz, gdy tworzysz taki raport. W takim przypadku warto skorzystać z pomocy księgowego lub specjalisty ds. podatków, aby upewnić się, że wszystkie dokumenty są poprawnie wypełnione i deklaracja została złożona prawidłowo.',
                    type: 'bot'
                }
            ];
        case 'a1b2c3d4-e5f6-g7h8-i9j0-k1l2m3n4o5p9':
            return MOCK_MESSAGES;
        default:
            return [];
    }
}

const MOCK_CHAT_ENABLED = true;

function AiAssistant() {
    const [chats, setChats] = React.useState<{ id: string, title: string }[]>(data as { id: string, title: string }[]);
    const [userMessage, setUserMessage] = React.useState<string>('');
    const [activeChat, setActiveChat] = React.useState<string | null>(null);
    const [messages, setMessages] = React.useState<{ message: string, type: string }[]>([] as {
        message: string,
        type: string
    }[]);
    const [botTyping, setBotTyping] = React.useState<boolean>(false);
    const messagesEndRef = useRef(null as any)

    const loadChat = (id: string) => {
        setActiveChat(id);
        setMessages(getMessages(id));
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollTo({ top: messagesEndRef.current?.scrollHeight, behavior: "smooth" })
        console.log(messagesEndRef.current?.scrollHeight)
    }

    const sendMessage = () => {
        if (userMessage.length === 0) {
            return;
        }

        if (!MOCK_CHAT_ENABLED) {
            if (!activeChat) {
                const chat = {id: (Math.random() * 1000000).toString(), title: userMessage};
                setChats([...chats, chat]);
                setActiveChat(chat.id);
                setUserMessage('');
                setMessages([]);
            }

            const message = {message: userMessage, type: 'user'};
            setMessages([...messages, message]);
            setUserMessage('');
        } else {
            setBotTyping(true);
            setTimeout(() => {
                scrollToBottom();
            }, 100)

            if (userMessage.startsWith("Wyobraź sobie")) {
                const chat = {
                    id: "a1b2c3d4-e5f6-g7h8-i9j0-k1l2m3n4o5p9",
                    title: 'Raport na temat stanu Ławek przy ulicy Kwiatowej'
                };
                setChats([...chats, chat]);
                setActiveChat(chat.id);
                const message = {message: userMessage, type: 'user'};
                const msgs = [message];
                setMessages(msgs);

                setTimeout(() => {
                    setMessages([...msgs, MOCK_MESSAGES[1]])
                    setBotTyping(false);
                    setTimeout(() => {
                        scrollToBottom();
                    }, 100)
                }, 3000)
                setUserMessage('');
            }

            if (userMessage.startsWith("Teraz napisz")) {
                const message = {message: userMessage, type: 'user'};
                const msgs = [...messages, message];
                setMessages(msgs);

                setTimeout(() => {
                    setMessages([...msgs, MOCK_MESSAGES[3]])
                    setBotTyping(false);
                    setTimeout(() => {
                        scrollToBottom();
                    }, 100)
                }, 3000)
                setUserMessage('');
            }
        }
    }

    const newChat = () => {
        setActiveChat(null);
        setMessages([]);
    }

    return (
        <div className="aiassistant">
            <Layout className={'aiassistant__wrapper'}>
                <Sider className={'aiassistant__sider'} width={300}>
                    <List
                        size="large"
                        bordered
                        header={<List.Item className={'aiassistant__sider-entry'}
                                           onClick={newChat}>Nowy chat</List.Item>}
                        dataSource={chats}
                        renderItem={(item) => <List.Item className={'aiassistant__sider-entry'}
                                                         onClick={() => loadChat(item.id)}>{item.title}</List.Item>}
                    >
                    </List>
                </Sider>
                <Layout className={'aiassistant__content-wrapper'}>
                    <Content className={'aiassistant__content'}>
                        <div className={'aiassistant__wrapper'} ref={messagesEndRef}>
                                {activeChat && messages.map((message: any, index: number) => {
                                    return (
                                        <div
                                            key={index}
                                            className={'aiassistant__message ' + (message.type === 'user' ? 'aiassistant__message--user' : 'aiassistant__message--bot')}>
                                            {message.mode === 'HTML' ?
                                                <div dangerouslySetInnerHTML={{__html: message.message}}/> :
                                                <pre style={{whiteSpace: 'pre-wrap'}}>{message.message}</pre>}
                                        </div>
                                    )
                                })}
                                {botTyping && <div className={'aiassistant__message aiassistant__message--bot'}><Loader
                                    style={{height: '40px'}}/></div>}
                                {!activeChat &&
                                    <div className={'aiassistant__message--new-chat'}>Napisz wiadomość, aby rozpocząć
                                        czat z naszym asystentem AI.</div>}
                        </div>
                    </Content>
                    <Footer className={'aiassistant__footer'}>
                        <Space.Compact style={{width: '100%'}} size={'large'}>
                            <Input placeholder="Napisz wiadomość..." value={userMessage}
                                   onChange={(data) => setUserMessage(data.target.value)}/>
                            <Button type="primary" onClick={sendMessage}>Wyślij</Button>
                        </Space.Compact>
                    </Footer>
                </Layout>
            </Layout>
        </div>
    );
}

export default AiAssistant;
