import React from 'react';
import {Button, Descriptions, Drawer, Space} from "antd";
import {ScopeMapper} from "../../screens/users/Users";
import {User} from "../../types/AuthState";
import {useAuth} from "../../extensions/Auth";

export interface UserDrawerProps {
    selectedUser: User;
    drawerOpen: boolean;
    closeDrawer: () => void;
}

function UserDrawer({selectedUser, drawerOpen, closeDrawer}: UserDrawerProps) {
    const auth = useAuth();

    return (
        <Drawer title="Informacje o użytkowniku" placement="right" onClose={closeDrawer} open={drawerOpen}
                extra={
                    <Space>
                        <Button onClick={() => auth.navigate(`/users/edit/${selectedUser.email}`)} type="primary">
                            Edytuj
                        </Button>
                    </Space>
                }>
            <Descriptions title={selectedUser.name} bordered>
                <Descriptions.Item label="Imię" span={3}>{selectedUser.firstName}</Descriptions.Item>
                <Descriptions.Item label="Nazwisko" span={3}>{selectedUser.lastName}</Descriptions.Item>
                <Descriptions.Item label="Email" span={3}>{selectedUser.email}</Descriptions.Item>
                <Descriptions.Item label="Dział" span={3}>{selectedUser.organisation}</Descriptions.Item>
                <Descriptions.Item label="Stanowisko" span={3}>{selectedUser.position}</Descriptions.Item>
                <Descriptions.Item label="Przydział urlopu" span={3}>{selectedUser.holidayAllowance}</Descriptions.Item>
                <Descriptions.Item label="Uprawnienia" span={3}><ul>{selectedUser.scopes?.map(x => <li>{ScopeMapper(x)}</li>)}</ul></Descriptions.Item>
            </Descriptions>
        </Drawer>
    );
}

export default UserDrawer;
