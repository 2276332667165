import {BACKEND_BASE, GetToken, Logout} from "./LoginService";
import {SiteSettings, SystemSettings} from "../types/System";
import {UserApp} from "../types/AuthState";

export const SystemService = {
    getSystemSettings: async (): Promise<SystemSettings> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/System`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if(response.status === 401) {
                throw new Error('unauthorized');
            }

            return await response.json();
        } catch (e) {
            Logout();
            throw new Error('unauthorized');
        }
    },
    updateAuthMode: async (mode: number): Promise<boolean> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/System/auth`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify({authenticationMode: mode})
            });

            console.log(response.status)
            if(response.status === 401) {
                throw new Error('unauthorized');
            }

            return response.status === 200;
        } catch (e) {
            Logout();
            throw new Error('unauthorized');
        }
    },
    updateLdapSettings: async (settings: any): Promise<boolean> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/System/ldap`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify({ldapServer: settings})
            });

            if(response.status === 401) {
                throw new Error('unauthorized');
            }

            return response.status === 200;
        } catch (e) {
            Logout();
            throw new Error('unauthorized');
        }
    },
    getDefaultUserApps: async () => {
        try {
            const response = await fetch(`${BACKEND_BASE}/System/defaultUserApps`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if(response.status === 401) {
                Logout();
                return;
            }

            return await response.json();
        } catch (e) {
            return false;
        }
    },
    addDefaultUserApp: async (app: UserApp): Promise<boolean> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/System/defaultUserApp`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(app)
            });

            if(response.status === 401) {
                Logout();
                return false;
            }

            return response.status === 200;
        } catch (e) {
            return false;
        }
    },
    editDefaultUserApp: async (app: UserApp) => {
        try {
            const response = await fetch(`${BACKEND_BASE}/System/defaultUserApp/${app.id}`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(app)
            });

            if(response.status === 401) {
                Logout();
                return;
            }

            return response.status === 200;
        } catch (e) {
            return false;
        }
    },
    deleteDefaultUserApp: async (id: string) => {
        try {
            const response = await fetch(`${BACKEND_BASE}/System/defaultUserApp/${id}`, {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if(response.status === 401) {
                Logout();
                return;
            }

            return response.status === 200;
        } catch (e) {
            return false;
        }
    },
    getSiteSettings: async (): Promise<SiteSettings | null> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/System/siteSettings`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true'
                }
            });

            return await response.json();
        } catch (e) {
            return null;
        }
    },
    updateSiteSettings: async (settings: SiteSettings): Promise<boolean> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/System/siteSettings`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(settings)
            });

            if(response.status === 401) {
                Logout();
                return false;
            }

            return response.status === 200;
        } catch (e) {
            return false;
        }
    }
}
