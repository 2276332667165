import React from 'react';
import './SoftwareCard.css';
import {Avatar, Card, Skeleton} from "antd";
import Meta from "antd/es/card/Meta";

export interface SoftwareCardProps {
    title: string;
    description: string;
    avatar: string;
    url?: string;
    onClick?: () => void;
}

function SoftwareCard(props: SoftwareCardProps) {
    const {title, description, avatar, onClick} = props;
    return (
        <Card
            hoverable
            className={'software__card'}
            onClick={onClick ? onClick : () => {}}
        >
            <Skeleton loading={false} avatar active>
                <Meta
                    avatar={<Avatar style={{background: '#D1522E'}} src={avatar} />}
                    title={title}
                    description={description}
                />
            </Skeleton>
        </Card>
    );
}

export default SoftwareCard;
