import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import './SoftwareAdd.css';
import {useAuth} from "../../extensions/Auth";
import {Button, Form, Input, notification} from "antd";
import {SoftwareService} from "../../services/SoftwareService";
import {AdminScopes} from "../../types/Scopes";

const {TextArea} = Input;

function SoftwareAdd() {
    const auth = useAuth();
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [icon, setIcon] = useState('');
    const [link, setLink] = useState('');

    const updateField = (e: any, updater: any) => {
        updater(e.target.value);
    }

    useEffect(() => {
        if(!auth.user?.scopes?.includes(AdminScopes.SOFTWARE)) {
            auth.navigate('/software');
            return;
        }
    }, []);

    const publish = () => {
        if (!title || !description || !icon) {
            api.error({
                message: `Uzupełnij wymagane pola`,
                description: 'Tytuł, opis i ikona nie mogą być puste!',
                placement: 'top'
            });
            return;
        }

        if (title.length > 50) {
            api.error({
                message: `Zbyt długi tytuł`,
                description: 'Tytuł może mieć maksymalnie 50 znaków!',
                placement: 'top'
            });
            return;
        }

        const data = {
            title,
            description,
            url: link,
            avatar: icon
        }

        try {
            SoftwareService.addSoftware(data).then(() => {
                api.info({
                    message: `Dodano oprogramowanie`,
                    description: 'Oprogramowanie zostało dodane!',
                    placement: 'top'
                });
                setTitle('');
                setDescription('');
                setIcon('');
                setLink('');

                form.resetFields();
            });
        } catch (e) {
            api.error({
                message: `Błąd`,
                description: 'Wystąpił błąd podczas dodawania oprogramowania!',
                placement: 'top'
            });
        }
    }

    return (
        <div className="software">
            {contextHolder}
            <Form
                layout={'vertical'}
                form={form}
                className={'software__form'}
            >
                <Form.Item label="Tytuł" name="title" rules={[{required: true}]}>
                    <Input size="large" placeholder="Tytuł oprogramowania" value={title}
                           onChange={(e) => updateField(e, setTitle)} className={'software__field'}/>
                </Form.Item>
                <Form.Item label="Opis" name="description" rules={[{required: true}]}>
                    <TextArea size="large" placeholder="Opis oprogramowania" value={description}
                              onChange={(e) => updateField(e, setDescription)} className={'software__field'} rows={4}/>
                </Form.Item>
                <Form.Item label="Ikona" name="avatar" rules={[{required: true}]}>
                    <Input size="large" placeholder="Link do ikony" value={icon}
                           onChange={(e) => updateField(e, setIcon)} className={'software__field'}/>
                </Form.Item>
                <Form.Item label="Link" name="url">
                    <Input size="large" placeholder="Link do oprogramowania" value={link}
                           onChange={(e) => updateField(e, setLink)} className={'software__field'}/>
                </Form.Item>
            </Form>
            <Button type={'primary'} onClick={publish}>Dodaj</Button>
        </div>
    );
}

export default SoftwareAdd;
