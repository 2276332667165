import React, {useState} from 'react';
import './Info.css';
import {Tabs} from 'antd';
import InfoGeneral from "./infogeneral/InfoGeneral";
import InfoDepartment from "./infodepartment/InfoDepartment";

const options = [
    {label: 'Ogólne', key: 'general', children: <InfoGeneral />},
    {label: 'Sprawy międzywydziałowe', key: 'interdepartment', children: <InfoDepartment />}
];

function Info() {
    return (
        <div className="info">
            <Tabs
                defaultActiveKey="general"
                centered
                items={options}
            />
        </div>
    );
}

export default Info;
