import React from 'react';
import './InfoGeneral.css';
import {Tabs} from "antd";
import PhoneNumbers from "../../../mockscreens/PhoneNumbers";
import TrainingCourses from "../../../mockscreens/TrainingCourses";
import Updates from "../../../mockscreens/Updates";
import Covid from "../../../mockscreens/Covid";
import Regulations from "../../../mockscreens/Regulations";

const items = [
    {label: "Numery wewnętrzne", key: 'general-1', children: <PhoneNumbers />},
    {label: "Oferty szkoleń", key: 'general-2', children: <TrainingCourses />},
    {label: "Rejestr zmian aktualizacji", key: 'general-3', children: <Updates />},
    {label: "COVID", key: 'general-4', children: <Covid />},
    {label: "Regulaminy pracodawcy", key: 'general-5', children: <Regulations />},
    {label: "GLPI", key: 'general-6', children: <div />},
]

function InfoGeneral() {
    return (
        <div className="infogeneral">
            <Tabs defaultActiveKey="general-1" tabBarStyle={{width: 300}} items={items} tabPosition={'left'}  />
        </div>
    );
}

export default InfoGeneral;
